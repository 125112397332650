import { useState } from "react";
import { useAppDispatch } from "../../../libs/hook";
import { AuthenticationService } from "../../../core/services/common/authentication.service";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { AuthConstant } from "../../../core/constants/auth.constant";
import { hideSpinner, showSpinner } from "../../../libs/features/spinnerSlice";
import { useNavigate } from "react-router-dom";
import FormFloatingPassword from "../../../components/ui/form/FormFloatingPassword";

export default function Login() {
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loginRequest, setLoginRequest] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);

  const onChange = (event: any) => {
    loginRequest[event.target.name] = event.target.value;
    setLoginRequest({ ...loginRequest });
  };

  const handleLogin = () => {
    setSubmitted(true);
    if (!loginRequest.username || loginRequest.username?.trim().length <=0 || !loginRequest.password || loginRequest.password?.trim().length <=0) {
      return;
    }
    dispatch(showSpinner());
    AuthenticationService.login(loginRequest).finally(() =>{
      setTimeout(() => {
        dispatch(hideSpinner());
      }, 500);
    }).then((res) => {
      if (res.data.status) {
        cookies.set(AuthConstant.ACCESS_TOKEN, res.data.responseData.jwt);
        navigate("/dashboard");
        return;
      }
      toast.error("Username or password is incorrect");
    });
  };

  return (
    <>
      <div className="position-relative">
        <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
          <div className="authentication-inner py-6">
            <div className="card p-md-7 p-1">
              <div className="app-brand justify-content-center mt-5">
                <a href="index.html" className="app-brand-link gap-2">
                  LOGO
                </a>
              </div>

              <div className="card-body mt-1">
                <h4 className="mb-1">Chào mừng đến với TL! 👋</h4>
                <p className="mb-5">
                  Vui lòng đăng nhập vào tài khoản của bạn và bắt đầu.
                </p>

                <form className="mb-5">
                  <div className="form-floating form-floating-outline mb-5">
                    <input
                      type="text"
                      className={'form-control' + ((submitted && (!loginRequest.username || loginRequest.username?.trim().length <= 0)) ? ' is-invalid' : '')} 
                      name="username"
                      placeholder="Enter your email or username"
                      onChange={(e) => onChange(e)}
                    />
                    {
                      (submitted && (!loginRequest.username || loginRequest.username?.trim().length <= 0)) && <div className="error-feedback"> Username is required. </div>
                    }
                    <label htmlFor="email">Tên tài khoản</label>
                  </div>
                  <div className="mb-5"> 
                    <FormFloatingPassword name="password" onChange={(e) => onChange(e)} label="Mật khẩu" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" isError={(submitted && (!loginRequest.password || loginRequest.password?.trim().length <= 0))} errMsg="Password is required."></FormFloatingPassword>
                    {/* <div className="form-password-toggle">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <input
                            type="password"
                            className={'form-control' + ((submitted && (!loginRequest.password || loginRequest.password?.trim().length <= 0)) ? ' is-invalid' : '')}
                            name="password"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            onChange={(e) => onChange(e)}
                          />
                          
                          <label htmlFor="password">Password</label>
                        </div>
                        <span className="input-group-text cursor-pointer">
                          <i className="ri-eye-off-line"></i>
                        </span>
                      </div>
                      {
                        (submitted && (!loginRequest.password || loginRequest.password?.trim().length <= 0)) && <div className="error-feedback"> Password is required. </div>
                      }
                    </div> */}
                  </div>
                  <div className="mb-5 d-flex justify-content-between mt-5">
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label className="form-check-label" htmlFor="remember-me">
                        Nhớ mật khẩu
                      </label>
                    </div>
                    <a
                      href="auth-forgot-password-basic.html"
                      className="float-end mb-1 mt-2"
                    >
                      <span>Quên mật khẩu?</span>
                    </a>
                  </div>
                  <div className="mb-5">
                    <button
                      className="btn btn-primary d-grid w-100"
                      type="button"
                      onClick={handleLogin}
                    >
                      Đăng nhập
                    </button>
                  </div>
                </form>

                {/* <p className="text-center">
                  <span>New on our platform? </span>
                  <a href="auth-register-basic.html">
                    <span>Create an account</span>
                  </a>
                </p> */}

                {/* <div className="divider my-5">
                  <div className="divider-text">or</div>
                </div>

                <div className="d-flex justify-content-center gap-2">
                  <a className="btn btn-icon rounded-circle btn-text-facebook">
                    <i className="tf-icons ri-facebook-fill"></i>
                  </a>

                  <a className="btn btn-icon rounded-circle btn-text-twitter">
                    <i className="tf-icons ri-twitter-fill"></i>
                  </a>

                  <a className="btn btn-icon rounded-circle btn-text-github">
                    <i className="tf-icons ri-github-fill"></i>
                  </a>

                  <a className="btn btn-icon rounded-circle btn-text-google-plus">
                    <i className="tf-icons ri-google-fill"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
