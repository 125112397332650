import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { ShipmentService } from '../../../core/services/shipment.service';
import NumberFormat from '../../../components/common/NumberFormat';
import Currency from '../../../components/common/Currency';

type CCFormProps = {
  orderDetail: any;
  onSubmit?: (event: any) => void;
  onCancel?: (event?: any) => void;
  // shipments: any[];
  shipment: any;
};

export default function CCForm({ onCancel, onSubmit, orderDetail, shipment }: CCFormProps) {
  const dispatch = useAppDispatch();
  // const [files, setFiles] = useState<any[]>([]);

  const onSubmitForm = () => {

    // if (!files || files?.length <= 0) {
    //   toast.error('Chưa upload hình ảnh');
    //   return;
    // }
    let ccRequest = {
      shipmentId: shipment.id,
      orderId: orderDetail.orderId
    };
    dispatch(showSpinner());
    ShipmentService.ccShipment(ccRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          onSubmit?.(ccRequest);
          toast.success("Cập nhật thông quan thành công");
        } else {
          toast.error("Đã có lỗi xảy tra trong quá trình xử lý, vui lòng thử lại");
        }
      });
  };

  // const onChangeFile = (event: any) => {
  //   setFiles(event.filesToAdd);
  // };


  return (
    <>
      <div className="modal-body">
        <div className="row">
          {/* {shipments?.map((shipment, idx) => ( */}
          <div className="col-12 py-3 d-flex">
            {/* <div className="form-check form-check-primary mt-4 me-5">
                <input className="form-check-input" name={'cc-' + idx} type="checkbox" value={shipment.id} onChange={(event) => onChange(event)} />
              </div> */}
            <div className="accordion-header border-0">
              <div role="button" className="accordion-button pe-0 shadow-none collapsed">
                <div className="d-flex align-items-center">
                  <div className="avatar-wrapper">
                    <div className="avatar me-4">
                      <span className="avatar-initial rounded-circle bg-label-secondary">
                        <i className="ri-car-line ri-24px"></i>
                      </span>
                    </div>
                  </div>
                  <span className="d-flex flex-column fw-normal">
                    <span className="text-heading mb-1">
                      <span className="fw-semibold">{shipment.licensePlate}</span>
                      { shipment.driverName && <span className="fw-semibold ps-4">{shipment.driverName}</span>}
                      {shipment.driverPhoneNumber && <span className="text-body px-4">{shipment.driverPhoneNumber}</span>}
                    </span>
                    <div>
                      <div className="py-1 d-inline-block justify-content-between">
                        <span>Số cân</span>
                        <span className="fw-semibold ps-3">{shipment.weight ? <>{shipment.weight} kg</> : '-'} </span>
                      </div>
                      <div className="py-1 d-inline-block justify-content-between ps-5">
                        <span>Số khối</span>
                        <span className="fw-semibold ps-3">
                          {shipment.size ? <>{shipment.size} m<sup>3</sup></> : '-'}
                        </span>
                      </div>
                      <div className="py-1 d-inline-block justify-content-between ps-5">
                        <span>Số kiện</span>
                        <span className="fw-semibold ps-3">{shipment.numberParcel}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-between field-info">
              <div>Phí vận chuyển</div>
              <div><Currency price={shipment.shippingFee ? shipment.shippingFee : 0}></Currency></div>
            </div>
          </div>
          <div className="col-12">
            <div className="py-3 border-bottom">
              <div>Cước phụ</div>
              <div className="ps-10 mt-2">
                <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Cước nội địa</div>
                      <div><Currency price={shipment.domesticFee ? shipment.domesticFee : 0}></Currency></div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Kéo xe</div>
                      <div><Currency price={shipment.towingFee ? shipment.towingFee : 0}></Currency></div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Xe nâng</div>
                      <div><Currency price={shipment.forkliftFee ? shipment.forkliftFee : 0}></Currency></div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Đóng gỗ</div>
                      <div><Currency price={shipment.woodenFee ? shipment.woodenFee : 0}></Currency></div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Thanh toán hộ</div>
                      <div><Currency price={shipment.payOnHehalf ? shipment.payOnHehalf : 0}></Currency></div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            {/* <div className="pb-3 d-flex justify-content-between field-info border-bottom">
              <div>Tổng tiền</div>
              <div className="fw-semibold">
                <Currency price={orderDetail.totalFee}></Currency>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between field-info">
              <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
              <div className="text-danger fw-semibold"><Currency price={shipment.payAmount ? shipment.payAmount : 0}></Currency></div>
            </div> */}
            <div className="py-3 d-flex justify-content-between field-info ">
              <div className="fs-5 fw-semibold">Tổng tiền</div>
              <div className="text-danger fw-semibold">
                <Currency price={orderDetail.totalFee}></Currency>
              </div>
            </div>
            <div className="d-flex justify-content-between field-info">
              <div >Số tiền cần thanh toán</div>
              <div className="fw-semibold">
                <Currency price={shipment.payAmount ? shipment.payAmount : 0}></Currency>
              </div>
            </div>
          </div>
          {/* ))} */}

          {/* <div className="col-12 py-3">
            <div className="text-heading mb-2">Hình ảnh chuyến hàng</div>
            <UploadFile onChange={onChangeFile} multiple></UploadFile>
          </div> */}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => onCancel?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Xác nhận thông quan
        </button>
      </div>
    </>
  );
}
