import { useEffect, useState } from 'react';
import FormFloating from '../../components/ui/form/FormFloating';
import { UserService } from '../../core/services/user.service';
import { PaginationConfig } from '../../core/config/PaginationConfig';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import ViewRole from '../../components/common/ViewRole';
import Pagination from '../../components/ui/Pagination';
import AppModel from '../../components/ui/AppModel';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { CommonConstant } from '../../core/constants/common.constant';

export default function InvMgt() {
  const dispatch = useAppDispatch();
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [users, setUsers] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenAddOrEditModal, setIsOpenAddOrEditModal] = useState<boolean>(false);
  const [userEdit, setUserEdit] = useState<any>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    searchRequest.page = 0;
    searchRequest.keyword = null;
    searchRequest.status = null;
    setSearchRequest({ ...searchRequest });
    getUserByPage(searchRequest);
  };

  const getUserByPage = (searchRequest: any) => {
    dispatch(showSpinner());
    UserService.getByPage(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setUsers(res.data);
        setTotalRecords(res.headers['x-total-count']);
      });
  };
  const onChanePagination = (event: any) => {
    searchRequest.page = event - 1;
    setSearchRequest({ ...searchRequest });
    getUserByPage(searchRequest);
  };

  const onSearch = () => {
    getUserByPage(searchRequest);
  };

  const onRefresh = () => {
    init();
  };

  const onChangeInput = (event: any) => {
    searchRequest.keyword = event.target.value;
    setSearchRequest({ ...searchRequest });
  };

  const openAddOrEditModal = () => {
    setIsOpenAddOrEditModal(true);
  };

  const onCloseAddOrEditModal = (event: any) => {
    if (event) {
      init();
    }
    setIsOpenAddOrEditModal(false);
  }

  const disableUser = (userUid: string) => {
    Swal.fire({
      title: "Bạn có chắc chắn không?",
      text: "Bạn không thể hoàn tác lại được!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Xác nhận!",
      cancelButtonText: "Hủy"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showSpinner());
        UserService.disableUser(userUid).finally(() => {
          setTimeout(() => {
            dispatch(hideSpinner());
          }, 500);
        }).then(res => {
          if (!res.data.status) {
            toast.error("Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại");
            return;
          }
          toast.success('Vô hiệu hóa thành công');
        })
      }
    });
  }

  const onEditUser = (user: any) => {
    setUserEdit(user);
    setIsOpenAddOrEditModal(true);
  }

  return (
    <>
      <div className="card mb-6">
        <div className="card-header d-flex justify-content-between">
          <span className="fs-4 fw-bold">Quản người dùng</span>
        </div>
        <div className="card-body row">
          <div className="col-lg-3">
            <FormFloating label="Tìm kiếm" defaultValue={searchRequest.keyword} placeholder="Mã người dùng, khách hàng, người bán..." onChange={onChangeInput}></FormFloating>
          </div>
          {/* <div className="col-lg-3">
            <FormFloating label="Mã khách hàng" placeholder="Mã khách hàng"></FormFloating>
          </div> */}
          {/* <div className="col-lg-3">
            <FormFloatingSelect defaultValue={searchRequest.status} onChange={onChangeStatus} sources={orderStatusList} bindLabel="label" bindValue="value" label="Chọn trạng thái đơn hàng" placeholder="Trạng thái đơn hàng"></FormFloatingSelect>
          </div> */}
          <div className="col-lg-3">
            <button type="button" className="btn rounded-pill btn-icon btn-secondary btn-fab demo waves-effect waves-light me-2">
              <span className="tf-icons ri-reset-right-line ri-22px" onClick={onRefresh}></span>
            </button>
            <button type="button" className="btn rounded-pill btn-icon btn-primary btn-fab demo waves-effect waves-light" onClick={onSearch}>
              <span className="tf-icons ri-search-line ri-22px"></span>
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <button type="button" className="btn btn-primary btn-fab demo waves-effect waves-light" onClick={openAddOrEditModal}>
                <span className="tf-icons ri-add-line ri-16px me-2"></span>Thêm người dùng
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>Hình ảnh</th>
                  <th>Tên tài khoản</th>
                  <th>Họ và tên</th>
                  <th>Email</th>
                  <th>SĐT</th>
                  <th>Vai trò</th>
                  <th>Trạng thái</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {users.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className="avatar avatar-sm pull-up">
                        <img src="../../assets/img/avatars/5.png" alt="Avatar" className="rounded-circle" />
                      </div>
                    </td>
                    <td>{item.username}</td>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>
                      <ViewRole roleId={item.role?.roleId}></ViewRole>
                      {/* <span className="badge rounded-pill bg-label-primary me-1">Active</span> */}
                    </td>
                    <td>
                      {item.status == CommonConstant.ACCOUNT_STATUS_ACTIVATED && <span className="badge rounded-pill bg-label-success">Hoạt động</span>}
                      {item.status == CommonConstant.ACCOUNT_STATUS_DISABLED && <span className="badge rounded-pill bg-label-danger">Đã vô hiệu hóa</span>}
                      {item.status == CommonConstant.ACCOUNT_STATUS_NOT_ACTIVATED && <span className="badge rounded-pill bg-label-secondary">Chưa kích hoạt</span>}
                    </td>
                    <td>
                      <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                          <i className="ri-more-2-line"></i>
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item waves-effect" onClick={() => onEditUser(item)}>
                            <i className="ri-pencil-line me-1"></i> Sửa đổi
                          </a>
                          <a className="dropdown-item waves-effect" onClick={() => disableUser(item.userUid)}>
                            <i className="ri-delete-bin-7-line me-1"></i> Vô hiệu hóa
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {totalRecords > PaginationConfig.PAGE_SIZE && (
        <div className="row mt-10">
          <div className="col-12 d-flex justify-content-center">
            <Pagination totalRecords={totalRecords} size={PaginationConfig.PAGE_SIZE} onChange={(data) => onChanePagination(data)}></Pagination>
          </div>
        </div>
      )}

      {/* <AppModel
        isShow={isOpenAddOrEditModal}
        onClose={() => {
          setIsOpenAddOrEditModal(false);
        }}
        title='Thêm người dùng'
        size="lg"
      >
        <AddOrEditUser user={userEdit} onClose={(data) => onCloseAddOrEditModal(data)} ></AddOrEditUser>
      </AppModel> */}
    </>
  );
}
