import { GridLoader } from "react-spinners";

export default function LoadingView() {
  return (
    <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '9999', opacity: '0.7', backgroundColor: '#000' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {/*<GridLoader size={15} color="#ffffff" />*/}
        <img src="/assets/img/truck2.gif" width="100" style={{filter: 'brightness(0) invert(1)'}}/>

        <div className="mt-3 text-white text-center fw-semibold">Loading...</div>
      </div>
    </div>
  );
}
