import React, { useEffect, useState } from 'react';
import { PaginationConfig } from '../../core/config/PaginationConfig';
import { OrderService } from '../../core/services/order.service';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/ui/Pagination';
import { CommonConstant } from '../../core/constants/common.constant';
import moment from 'moment';
import Currency from '../../components/common/Currency';
import FormFloating from '../../components/ui/form/FormFloating';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import AppModel from '../../components/ui/AppModel';
import ImportFileModal from './components/ImportFileModal';
import ViewOrderStatus from '../../components/common/ViewOrderStatus';
import ExportFileModal from './components/ExportFileModel';
import { Tooltip } from 'react-tooltip';
import NumberFormat from '../../components/common/NumberFormat';

export default function OrderMgt() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [orders, setOrders] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [orderStatusList, setOrderStatusList] = useState<any[]>(CommonConstant.COLLECTION_ORDER_STATUS);
  const [isOpenImportFileModal, setIsOpenImportFileModal] = useState<boolean>(false);
  const [isOpenExportFileModal, setIsOpenExportFileModal] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    searchRequest.size = PaginationConfig.ORDER_PAGE_SIZE;
    searchRequest.page = 0;
    searchRequest.keyword = null;
    searchRequest.status = null;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const getOrderByPage = (params: any) => {
    dispatch(showSpinner());
    OrderService.getByPage(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrders(res.data);
        setTotalRecords(res.headers['x-total-count']);
      });
  };

  const onViewDetail = (order: any) => {
    navigate(`/order-mgt/detail/${order.orderId}`);
  };

  const onChanePagination = (event: any) => {
    searchRequest.page = event - 1;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onSearch = () => {
    getOrderByPage(searchRequest);
  };

  const onRefresh = () => {
    init();
  };

  const onChangeInput = (event: any) => {
    searchRequest.keyword = event.target.value;
    setSearchRequest({ ...searchRequest });
  };

  const onChangeStatus = (data: any) => {
    searchRequest.status = data?.value ? data.value : null;
    setSearchRequest({ ...searchRequest });
  };

  const openImportFileModal = () => {
    setIsOpenImportFileModal(true);
  };

  const openExportFileModal = () => {
    setIsOpenExportFileModal(true);
  };

  const onCloseImportFileModal = (event: any) => {
    setIsOpenImportFileModal(false);
    if (event) {
      init();
    }
  };
  const onCloseExportFileModal = (event: any) => {
    setIsOpenExportFileModal(false);
    if (event) {
      init();
    }
  };

  return (
    <>
      <div className="card mb-6">
        <div className="card-header d-flex justify-content-between">
          <span className="fs-4 fw-bold">Quản lý đơn hàng</span>
          <div className="text-end d-inline-block">
            <div className="btn-group me-3">
              <button type="button" className="btn btn-excel" data-bs-toggle="dropdown">
                <span className="tf-icons ri-file-excel-line ri-16px me-2"></span>Thao tác Excel
              </button>
              <button type="button" className="btn btn-excel dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" onClick={() => openImportFileModal()}>
                    Nhập kho
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => openExportFileModal()}>
                    Xuất kho
                  </a>
                </li>
                <li>
                  <a className="dropdown-item">Xuất dữ liệu</a>
                </li>
              </ul>
            </div>
            {/*<button type="button" className="btn btn-excel btn-fab demo waves-effect waves-light me-3"*/}
            {/*        onClick={() => openImportFileModal()}>*/}
            {/*  <span className="tf-icons ri-file-excel-line ri-16px me-2"></span>Nhập kho bằng Excel*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-excel btn-fab demo waves-effect waves-light me-3"*/}
            {/*        onClick={() => openExportFileModal()}>*/}
            {/*  <span className="tf-icons ri-file-excel-line ri-16px me-2"></span>Xuất kho bằng Excel*/}
            {/*</button>*/}
            <button
              type="button"
              className="btn btn-primary btn-fab demo waves-effect waves-light"
              onClick={() => {
                navigate('/add-order');
              }}
            >
              <span className="tf-icons ri-add-line ri-16px me-2"></span>Nhập hàng
            </button>
          </div>
        </div>
        <div className="card-body row py-5">
          <div className="col-lg-3">
            <FormFloating label="Tìm kiếm" defaultValue={searchRequest.keyword} placeholder="Mã đơn hàng, khách hàng, người bán..." onChange={onChangeInput}></FormFloating>
          </div>
          {/* <div className="col-lg-3">
            <FormFloating label="Mã khách hàng" placeholder="Mã khách hàng"></FormFloating>
          </div> */}
          <div className="col-lg-3">
            <FormFloatingSelect defaultValue={searchRequest.status} onChange={onChangeStatus} sources={orderStatusList} bindLabel="label" bindValue="value" label="Chọn trạng thái đơn hàng" placeholder="Trạng thái đơn hàng"></FormFloatingSelect>
          </div>
          <div className="col-lg-3">
            <button type="button" className="btn rounded-pill btn-icon btn-secondary btn-fab demo waves-effect waves-light me-2">
              <span className="tf-icons ri-reset-right-line ri-22px" onClick={onRefresh}></span>
            </button>
            <button type="button" className="btn rounded-pill btn-icon btn-primary btn-fab demo waves-effect waves-light" onClick={onSearch}>
              <span className="tf-icons ri-search-line ri-22px"></span>
            </button>
          </div>
        </div>
      </div>

      <div className="row g-6">
        {orders.map((order, idx) => (
          <div key={idx} className="col-xl-4 col-lg-6 col-md-6">
            <div className="card cursor-pointer" onClick={() => onViewDetail(order)}>
              <div className="card-header pb-4">
                <div className="">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <span className="text-primary text-heading fs-5 fw-semibold" style={{ color: '#6d788d' }}>
                          <span style={{ color: '#6d788d' }}>#</span>
                          {order.orderId}
                        </span>
                        {order.warningYn && (
                          <span className="ms-2">
                            <i data-tooltip-id="my-tooltip" data-tooltip-content={order.warningMsg + '!'} className="fs-5 text-warning ri-error-warning-line"></i>
                            <Tooltip id="my-tooltip" style={{ backgroundColor: '#fff3dd', color: '#fdb528', zIndex: '9999', fontStyle: 'italic' }} />
                          </span>
                        )}
                      </div>
                      <ViewOrderStatus orderDetail={order}></ViewOrderStatus>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body pb-3">
                <div className="px-3 py-3 bg-lighter rounded-3">
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <span>Mã KH: </span> <span className="fw-medium text-heading">{order.customerInfo ? order.customerInfo.username : '--'}</span>
                    </div>
                    <div>
                      <span>SĐT: </span> <span className="fw-medium text-heading">{order.recipientPhoneNumber}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <span>Số kiện: </span> <span className="fw-medium text-heading"><NumberFormat value={order.numberParcel}></NumberFormat></span>
                    </div>
                    <div>
                      <span>Số cân: </span> <span className="fw-medium text-heading">{order.weight ? <><NumberFormat value={order.weight}></NumberFormat> kg</> : '--'}</span>
                    </div>
                    <div>
                      <span>Số khối: </span>
                      <span className="fw-medium text-heading">
                        {order.size ? <><NumberFormat value={order.size}></NumberFormat> m<sup>3</sup></> : '--'}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>Seller: </span>
                      <span className="fw-medium text-heading">{order.sellerInfo ? order.sellerInfo.username : '--'}</span>
                    </div>
                    <div>
                      <span>Điểm đến: </span>
                      <span className="fw-medium text-heading">{order.address ? order.address : '--'}</span>
                    </div>
                  </div>
                </div>

                  {order.note && (
                    <p className="mt-3 mb-0">
                      Note: <span className="fst-italic fw-semibold">{order.note ? order.note : '--'}</span>
                    </p>
                  )}
              </div>
              <div className="card-body border-top py-4">
                <div className="d-flex justify-content-between">
                  <span className="fst-italic">{moment(order.createdDate).format('YYYY/MM/DD HH:mm')}</span>
                  <span className="fw-bold text-primary fs-5">{order.totalFee ? <Currency price={order.totalFee} /> : '-- ¥'}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {totalRecords <= 0 && (
        <div className="row">
          <div className="col-12 text-center mt-12">Không có đơn hàng nào.</div>
        </div>
      )}

      {totalRecords > PaginationConfig.ORDER_PAGE_SIZE && (
        <div className="row mt-10">
          <div className="col-12 d-flex justify-content-center">
            <Pagination totalRecords={totalRecords} size={PaginationConfig.ORDER_PAGE_SIZE} onChange={(data) => onChanePagination(data)}></Pagination>
          </div>
        </div>
      )}

      <AppModel
        isShow={isOpenImportFileModal}
        onClose={() => {
          setIsOpenImportFileModal(false);
        }}
        title="Tạo đơn bằng file excel"
        size="lg"
      >
        <ImportFileModal onClose={(data) => onCloseImportFileModal(data)}></ImportFileModal>
      </AppModel>

      <AppModel
        isShow={isOpenExportFileModal}
        onClose={() => {
          setIsOpenExportFileModal(false);
        }}
        title="Xuất kho bằng file excel"
        size="lg"
      >
        <ExportFileModal onClose={(data) => onCloseExportFileModal(data)}></ExportFileModal>
      </AppModel>
    </>
  );
}
