import axios from 'axios';

export const UserService = {
  getAllSeller() {
    const url = process.env.REACT_APP_API_URL + '/user/get-sellers';
    return axios.get(url);
  },

  getAllCustomer() {
    const url = process.env.REACT_APP_API_URL + '/user/get-customers';
    return axios.get(url);
  },

  changePassword(changePasswordRequest: any) {
    const url = process.env.REACT_APP_API_URL + '/user/change-password';
    return axios.post(url, changePasswordRequest);
  },

  getByPage(request: any) {
    const url = process.env.REACT_APP_API_URL + '/user/get-by-page';
    return axios.post(url, request);
  },

  createUser(request: any) {
    const url = process.env.REACT_APP_API_URL + '/user/create-user';
    return axios.post(url, request);
  },

  editUser(request: any) {
    const url = process.env.REACT_APP_API_URL + '/user/edit-user';
    return axios.post(url, request);
  },

  disableUser(userUid: string) {
    const url = process.env.REACT_APP_API_URL + '/user/disable-user?userUid=' + userUid;
    return axios.get(url);
  },

  updateCurrentUser(request: any) {
    const url = process.env.REACT_APP_API_URL + '/user/update-current-user';
    return axios.post(url, request);
  },

  updateAvatar(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = process.env.REACT_APP_API_URL + '/user/update-avatar';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },
};
