import React from "react";

interface IProps {
  price: number;
}

const Currency: React.FC<IProps> = ({ price }) => {
  const formattedPrice = Intl.NumberFormat('vi-VN').format(price);
  return (
    <>
    {/* {Intl.NumberFormat('vi-VN', {
      style: 'currency', 
      currency: 'VND' // Change this
      }).format(price)} */}
      {formattedPrice} ¥
    </>
  );
}

export default Currency;