import axios from "axios";
import Cookies from "universal-cookie";
import { AuthConstant } from "../constants/auth.constant";

axios.interceptors.request.use((req) => {
  const cookie = new Cookies();
  const token = cookie.get(AuthConstant.ACCESS_TOKEN);
  if (token) {
    req.headers["Authorization"] = "Bearer " + token;
  }
  return req;
});

axios.interceptors.response.use(
  (response) => {
    // if (response.status === 401) {
    // }
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 403 ||error.response.status === 401)) {
      const cookies = new Cookies();
      cookies.remove(AuthConstant.ACCESS_TOKEN);
      window.location.href = "/login"
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
