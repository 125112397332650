import { useEffect, useState } from 'react';
import FormFloating from '../../components/ui/form/FormFloating';
import { OrderService } from '../../core/services/order.service';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import UploadFile from '../../components/ui/UploadFile';
import { ShipmentService } from '../../core/services/shipment.service';
import { FileService } from '../../core/services/common/file.service';
import NumberFormat from '../../components/common/NumberFormat';
import FormFloatingNumber from '../../components/ui/form/FormFloatingNumber';

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

type IFormValues = {
  orderId: string;
  domesticFee: number;
  towingFee: number;
  // woodenFee: number;
  shippingFee: number;
  infrastructureFee: number;
  payOnHehalf: number;
  // commodityFee: number;
  forkliftFee: number;
  totalFee: number;
  // shipments: any;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  weight: number;
  size: number;
  numberParcel: number;
};

export default function ExportTracking() {
  const { id } = useParams();
  const [params] = useSearchParams();
  const shipmentId = params.get('id');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [exportRequest, setExportRequest] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [filesToAdd, setFilesToAdd] = useState<any[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<any[]>([]);
  const [trackingExport, setTrackingExport] = useState<any>({});

  useEffect(() => {
    getOrder();
  }, [id]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail(res.data.responseData);
        convertData(res.data.responseData);
      });
  };

  const convertData = (data: any) => {
    if (shipmentId) {
      let temp: any = data.shipments.find((item: any, idx: any) => item.id == shipmentId);
      setExportRequest({ ...temp });

      let trackingTemp = temp?.trackings[0];
      setTrackingExport({ ...trackingTemp });
    } else {
      exportRequest.address = data.address;
      setExportRequest({ ...exportRequest });
    }
  };

  const onSubmitExport = () => {
    if (exportRequest.id) {
      handleEditExportShipment();
    } else {
      handleCreateExportShipment();
    }
  };

  const handleCreateExportShipment = () => {
    setSubmitted(true);
    if (validate()) return;

    exportRequest.orderId = orderDetail.orderId;
    if (!filesToAdd || filesToAdd.length <= 0) {
      ShipmentService.exportShipment(exportRequest)
        .finally(() => {
          setTimeout(() => {
            dispatch(hideSpinner());
          }, 500);
        })
        .then((res) => {
          if (res.data.status) {
            toast.success('Xuất kho thành công');
            navigate(`/order-mgt/detail/${id}`);
            return;
          }
          toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
        });
      return;
    }else {


      // if (exportRequest.numberParcel && exportRequest.numberParcel > numberRemaningParcel) {
      //   toast.error('Số kiện xuất đã vượt quá, số kiện còn lại là ' + numberRemaningParcel);
      //   return;
      // }


      dispatch(showSpinner());
      FileService.multiFileUpload(filesToAdd).then(
        (res) => {
          if (res.data.status) {
            exportRequest.files = res.data.responseData;
            ShipmentService.exportShipment(exportRequest)
              .finally(() => {
                setTimeout(() => {
                  dispatch(hideSpinner());
                }, 500);
              })
              .then((res) => {
                if (res.data.status) {
                  toast.success('Xuất kho thành công');
                  navigate(`/order-mgt/detail/${id}`);
                  return;
                }
                toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
              });
          } else {
            toast.error('Đã có lỗi xảy ra trong quá trình tải ảnh, vui lòng thử lại.');
            setTimeout(() => {
              dispatch(hideSpinner());
            }, 500);
          }
        },
        (error) => {
          toast.error('Đã có lỗi xảy ra trong quá trình tải ảnh, vui lòng thử lại.');
          setTimeout(() => {
            dispatch(hideSpinner());
          }, 500);
        }
      );
    }
  };

  const handleEditExportShipment = () => {
    setSubmitted(true);
    if (validate()) return;
    // if (filesToDelete.length == trackingExport.trackingImages.length && filesToAdd.length <= 0) {
    //   toast.error('Hình ảnh còn thiếu');
    //   return;
    // }

    // if (exportRequest.numberParcel && exportRequest.numberParcel > (numberRemaningParcel + oldShipment.numberParcel)) {
    //   toast.error('Số kiện xuất đã vượt quá, số kiện còn lại là ' + (numberRemaningParcel + oldShipment.numberParcel));
    //   return;
    // }

    exportRequest.shipmentId = exportRequest.id;
    exportRequest.orderId = orderDetail.orderId;
    exportRequest.trackId = trackingExport.trackId;
    exportRequest.filesToDelete = filesToDelete;

    dispatch(showSpinner());
    if (filesToAdd && filesToAdd.length > 0) {
      FileService.multiFileUpload(filesToAdd).then((res) => {
        if (!res.data.status) {
          toast.error('Đã có lỗi xảy ra trong quá trình tải ảnh, vui lòng thử lại.');
          return;
        }
        exportRequest.filesToAdd = res.data.responseData;
        ShipmentService.editExportShipment(exportRequest)
          .finally(() => {
            setTimeout(() => {
              dispatch(hideSpinner());
            }, 500);
          })
          .then((res) => {
            if (res.data.status) {
              toast.success('Cập nhật thành công');
              navigate(`/order-mgt/detail/${id}`);
              return;
            }
            toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
          });
      });
    } else {
      ShipmentService.editExportShipment(exportRequest)
        .finally(() => {
          setTimeout(() => {
            dispatch(hideSpinner());
          }, 500);
        })
        .then((res) => {
          if (res.data.status) {
            toast.success('Cập nhật thành công');
            navigate(`/order-mgt/detail/${id}`);
            return;
          }
          toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
        });
    }
  };

  const validate = () => {
    if (
      //  !exportRequest.domesticFee ||
      // !exportRequest.towingFee ||
      // !exportRequest.forkliftFee ||
      // !exportRequest.woodenFee ||
      // !exportRequest.payOnHehalf ||
      // !exportRequest.commodityFee ||
      // !exportRequest.infrastructureFee ||
      // !exportRequest.shippingFee ||
      // !exportRequest.driverName ||
      // !exportRequest.driverPhoneNumber ||
      !exportRequest.address ||
      !exportRequest.numberParcel ||
      !exportRequest.licensePlate
    ) {
      return true;
    }

    return false;
  };

  const onChangeValue = (event: any) => {
    exportRequest[event.target.name] = event.target.value;
    const sum =
      Number(exportRequest.domesticFee || 0) +
      Number(exportRequest.towingFee || 0) +
      Number(exportRequest.forkliftFee || 0) +
      // Number(exportRequest.infrastructureFee || 0) +
      Number(exportRequest.shippingFee || 0) +
      // Number(exportRequest.commodityFee || 0) +
      Number(exportRequest.woodenFee || 0) +
      Number(exportRequest.payOnHehalf || 0);
    exportRequest.totalFee = sum;

    setExportRequest({ ...exportRequest });
  };

  const onChangeFile = (event: any) => {
    setFilesToAdd(event.filesToAdd);
    setFilesToDelete(event.filesToDelete);
  };

  return (
    <>
      <div className="app-ecommerce">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">{shipmentId ? 'Sửa đổi thông tin chuyến hàng' : 'Xuất kho'}</h4>
            {/* <p className="mb-0">Orders placed across your store</p> */}
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={() => navigate(`/order-mgt/detail/${id}`)}>
              Hủy
            </button>
            <button type="button" className="btn btn-primary" onClick={onSubmitExport}>
              Lưu
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin chung</h5>
              </div>
              <div className="card-body">
                {/* <h5 className="card-title mb-6">Thông tin hàng hóa</h5> */}
                <div className="row">
                  <div className="col-6">
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Số kiện</div>
                      <div>{orderDetail.numberParcel}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Loại hàng hóa</div>
                      <div>{orderDetail.productType}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Số cân</div>
                      <div>{orderDetail.weight ? <>{orderDetail.weight} kg</> : '-'}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Số khối</div>
                      <div>
                        {orderDetail.size ? (
                          <>
                            {orderDetail.size} m<sup>3</sup>
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-3">
                    <FormFloating
                      type="text"
                      label="Điểm đến"
                      placeholder="Điểm đến"
                      name="address"
                      maxLength={2}
                      minLength={2}
                      isError={submitted && (!exportRequest.address || exportRequest.address?.trim().length <= 0) ? true : false}
                      errMsg="Điểm đến còn trống"
                      onChange={onChangeValue}
                      defaultValue={exportRequest.address || ''}
                      disabled={orderDetail.address ? true : false}
                    ></FormFloating>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin cước phí</h5>
              </div>
              <div className="card-body">
                {/* <h5 className="card-title mb-6">Thông tin hàng hóa</h5> */}
                <div className="row">
                  <div className="col-sm-12 py-3">
                    <FormFloatingNumber
                      label="Cước vận chuyển"
                      placeholder="Cước vận chuyển"
                      name="shippingFee"
                      onChange={onChangeValue}
                      defaultValue={exportRequest.shippingFee || 0}
                      min="0"
                    />
                    {/* <FormFloating
                      type="number"
                      label="Cước vận chuyển"
                      placeholder="Cước vận chuyển"
                      name="shippingFee"
                      onChange={onChangeValue}
                      value={exportRequest.shippingFee || 0}
                      min="0"
                      // isError={submitted && !exportRequest.shippingFee ? true : false}
                      // errMsg="Phí vận chuyển chưa nhập"
                    ></FormFloating> */}
                  </div>
                  <div className="col-12 py-3 border-bottom">
                    <div>Cước phụ</div>
                    <div className="ps-10 mt-2">
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Cước nội địa"
                            placeholder="Cước nội địa"
                            name="domesticFee"
                            onChange={onChangeValue}
                            defaultValue={exportRequest.domesticFee || 0}
                            min="0"
                          />
                          {/* <FormFloating
                            type="number"
                            label="Cước nội địa"
                            placeholder="Cước nội địa"
                            name="domesticFee"
                            onChange={onChangeValue}
                            value={exportRequest.domesticFee || 0}
                            min="0"
                            // isError={submitted && !exportRequest.domesticFee ? true : false}
                            // errMsg="Cước nội địa chưa nhập"
                          ></FormFloating> */}
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Kéo xe"
                            placeholder="Kéo xe"
                            name="towingFee"
                            onChange={onChangeValue}
                            defaultValue={exportRequest.towingFee || 0}
                            min="0"
                          />
                          {/* <FormFloating
                            type="number"
                            label="Kéo xe"
                            placeholder="Kéo xe"
                            name="towingFee"
                            onChange={onChangeValue}
                            value={exportRequest.towingFee || 0}
                            min="0"
                            // isError={submitted && !exportRequest.towingFee ? true : false}
                            // errMsg="Cước xe kéo chưa nhập"
                          ></FormFloating> */}
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Xe nâng"
                            placeholder="Xe nâng"
                            name="forkliftFee"
                            onChange={onChangeValue}
                            defaultValue={exportRequest.forkliftFee || 0}
                            min="0"
                          />
                          {/* <FormFloating
                            type="number"
                            label="Xe nâng"
                            placeholder="Xe nâng"
                            name="forkliftFee"
                            onChange={onChangeValue}
                            value={exportRequest.forkliftFee || 0}
                            min="0"
                            // isError={submitted && !exportRequest.forkliftFee ? true : false}
                            // errMsg="Cước xe nâng chưa nhập"
                          ></FormFloating> */}
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Đóng gỗ"
                            placeholder="Đóng gỗ"
                            name="woodenFee"
                            onChange={onChangeValue}
                            defaultValue={exportRequest.woodenFee || 0}
                            min="0"
                          />
                          {/* <FormFloating
                            type="number"
                            label="Đóng gỗ"
                            placeholder="Đóng gỗ"
                            name="woodenFee"
                            onChange={onChangeValue}
                            value={exportRequest.woodenFee || 0}
                            min="0"
                            // isError={submitted && !exportRequest.woodenFee ? true : false}
                            // errMsg="Cước xe đóng gỗ chưa nhập"
                          ></FormFloating> */}
                        </div>

                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Thanh toán hộ"
                            placeholder="Thanh toán hộ"
                            name="payOnHehalf"
                            onChange={onChangeValue}
                            defaultValue={exportRequest.payOnHehalf || 0}
                            min="0"
                          />
                          {/* <FormFloating
                            type="number"
                            label="Thanh toán hộ"
                            placeholder="Thanh toán hộ"
                            name="payOnHehalf"
                            onChange={onChangeValue}
                            value={exportRequest.payOnHehalf || 0}
                            min="0"
                            // isError={submitted && !exportRequest.payOnHehalf ? true : false}
                            // errMsg="Tiền thoanh toán hộ chưa nhập"
                          ></FormFloating> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 d-flex justify-content-between">
                    <div className="fs-5 fw-semibold">Tổng tiền</div>
                    {exportRequest.totalFee ? (
                      <div className="text-danger fw-semibold">
                        {' '}
                        <NumberFormat value={exportRequest.totalFee}></NumberFormat> ¥
                      </div>
                    ) : (
                      <div className="text-danger fw-semibold">--</div>
                    )}
                  </div>
                  <div className="col-12 py-6">
                    <FormFloatingNumber
                      label="Số tiền cần thanh toán"
                      placeholder="Số tiền cần thanh toán"
                      name="payAmount"
                      onChange={onChangeValue}
                      defaultValue={exportRequest.payAmount || 0}
                      min="0"
                    />
                    {/* <FormFloating
                      type="number"
                      label="Số tiền cần thanh toán"
                      placeholder="Số tiền cần thanh toán"
                      name="payAmount"
                      onChange={onChangeValue}
                      value={exportRequest.payAmount || 0}
                      min="0"
                      // isError={submitted && !exportRequest.shippingFee ? true : false}
                      // errMsg="Phí vận chuyển chưa nhập"
                    ></FormFloating> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-item-center">
                <h5 className="card-tile mb-0">Thông tin vận chuyển</h5>
                {/* <button type="button" className="btn btn-primary" onClick={onAddShipment}>
                  Thêm
                </button> */}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-lg-6 py-3">
                    {/* <FormFloating type="text" label="Biến số xe" placeholder="Biến số xe" {...register('licensePlate', { required: true })} isError={errors.licensePlate ? true : false} errMsg="Biến số xe còn trống"></FormFloating> */}
                    <FormFloating
                      type="text"
                      label="Biến số xe"
                      placeholder="Biến số xe"
                      name="licensePlate"
                      onChange={onChangeValue}
                      defaultValue={exportRequest.licensePlate || ''}
                      isError={submitted && (!exportRequest.licensePlate || exportRequest.licensePlate?.trim().length <= 0) ? true : false}
                      errMsg="Biến số xe còn trống"
                    ></FormFloating>
                  </div>
                  <div className="col-sm-12 col-lg-6 py-3">
                    <FormFloating
                      type="number"
                      label="Số kiện"
                      placeholder="Số kiện"
                      name="numberParcel"
                      onChange={onChangeValue}
                      defaultValue={exportRequest.numberParcel || ''}
                      isError={submitted && !exportRequest.numberParcel ? true : false}
                      errMsg="Số kiện còn trống"
                      min="1"
                    ></FormFloating>
                  </div>
                  <div className="col-sm-12 col-lg-6 py-3">
                    <FormFloating type="number" label="Số cân" placeholder="Số cân" name="weight" onChange={onChangeValue} defaultValue={exportRequest.weight || ''} min="0"></FormFloating>
                  </div>
                  <div className="col-sm-12 col-lg-6 py-3">
                    <FormFloating type="number" label="Số khối" placeholder="Số khối" name="size" onChange={onChangeValue} defaultValue={exportRequest.size || ''} min="0"></FormFloating>
                  </div>
                  {/*<div className="col-sm-12 col-lg-6 py-3">*/}
                  {/*  <FormFloating*/}
                  {/*    type="text"*/}
                  {/*    label="Tên tài xế"*/}
                  {/*    placeholder="Tên tài xế"*/}
                  {/*    name="driverName"*/}
                  {/*    onChange={onChangeValue}*/}
                  {/*    defaultValue={exportRequest.driverName || ''}*/}
                  {/*  ></FormFloating>*/}
                  {/*</div>*/}
                  {/*<div className="col-sm-12 col-lg-6 py-3">*/}
                  {/*  <FormFloating*/}
                  {/*    type="text"*/}
                  {/*    label="Số điện thoại"*/}
                  {/*    placeholder="Số điện thoại"*/}
                  {/*    name="driverPhoneNumber"*/}
                  {/*    onChange={onChangeValue}*/}
                  {/*    defaultValue={exportRequest.driverPhoneNumber || ''}*/}
                  {/*    maxLength={10}*/}
                  {/*  ></FormFloating>*/}
                  {/*</div>*/}
                  {/*{!shipmentId && (*/}
                  {/*  <div className="col-12 py-3">*/}
                  {/*    <div className="text-heading mb-2">Hình ảnh chuyến hàng</div>*/}
                  {/*    <UploadFile files={trackingExport?.trackingImages} onChange={onChangeFile} multiple></UploadFile>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
                {/* <div className="accordion ">
                  {shipments.map((item, idx) => (
                    <div key={idx} className=" shadow-none border-0 mb-3">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar-wrapper">
                            <div className="avatar me-4">
                              <span className="avatar-initial rounded-circle bg-label-secondary">
                                <i className="ri-car-line ri-24px"></i>
                              </span>
                            </div>
                          </div>
                          <span className="d-flex flex-column w-100">
                            <span className="py-1w-100" style={{ paddingRight: '1rem' }}>
                              <span className="text-heading mb-1 fw-semibold">{item.licensePlate}</span>
                              <span className="text-body px-4">
                                {item.driverName} - {item.driverPhoneNumber}
                              </span>
                              <a>
                                <i className="ri-image-line ri-18px"></i>
                              </a>
                            </span>
                            <div>
                              <div className="py-1 d-inline-block justify-content-between field-info">
                                <span>Số cân</span>
                                <span>{item.weight} kg</span>
                              </div>
                              <div className="py-1 d-inline-block justify-content-between field-info">
                                <span>Số khối</span>
                                <span>
                                  {item.size} m<sup>3</sup>
                                </span>
                              </div>
                              <div className="py-1 d-inline-block justify-content-between field-info">
                                <span>Số kiện</span>
                                <span>{item.numberParcel}</span>
                              </div>
                            </div>
                          </span>
                        </div>
                        <span style={{ width: '60px' }} className="d-flex justify-content-between">
                          <a className='cursor-pointer' onClick={() => onEdit(idx, item)}>
                            <i className="ri-edit-2-line ri-22px"></i>
                          </a>
                          <a className='cursor-pointer' onClick={() => onRemove(idx)}>
                            <i  className="ri-delete-bin-2-line ri-22px"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  {shipments?.length == 0 && <div className="py-5 text-center">Chưa có thông tin vận chuyển</div>}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AppModel isShow={isOpenModalShipment} onClose={() => onCloseModal} title="Thông tin chuyến hàng" size="lg">
        <ShipmentComp orderDetail={orderDetail} onCancel={onCloseModal} onSubmit={(data) => onSubmitShipment(data)} shipment={shipmentEdit}></ShipmentComp>
      </AppModel> */}
    </>
  );
}
