import { useState } from 'react';
import UploadFile from '../../../components/ui/UploadFile';
import { OrderService } from '../../../core/services/order.service';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';

type ImportFileModalProps = {
    onSubmit?: (event: any) => void;
    onClose?: (event?: any) => void;
};

export default function ImportFileModal(props: ImportFileModalProps) {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<any>();
  const [excelFile, setExcelFile] = useState<any>();

  const onChangeExcel = (event: any) => {
    setExcelFile(event.filesToAdd[0]);
  };

  const onChangeImage = (event: any) => {
    setImages(event.filesToAdd);
  };

  const onSubmitForm = () => {
    dispatch(showSpinner());
    OrderService.createOrUpdateFormFile(excelFile, images).finally(() => {
      setTimeout(() => {
        dispatch(hideSpinner());
      }, 500);
    }).then(res => {
      if (!res.data.status) {
        toast.error("Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại");
        return;
      }
      props.onClose?.(true);
    })
  }

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 py-3">
            {/* <div className="text-heading mb-2">File Excel</div> */}
            <UploadFile onChange={onChangeExcel} accept='.xlsx, .xls' multiple={false}></UploadFile>
          </div>
          <div className="col-12 py-3">
            <div className="text-heading mb-2">Hình ảnh các đơn hàng</div>
            <UploadFile onChange={onChangeImage} accept='image/*' multiple></UploadFile>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => props.onClose?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Thêm
        </button>
      </div>
    </>
  );
}
