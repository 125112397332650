import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: false
}

const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers: {
        showSpinner: (state) => {
            state.loading = true
        },
        hideSpinner: (state) => {
            state.loading = false
        }
    }
})

export default spinnerSlice.reducer
export const { showSpinner, hideSpinner } = spinnerSlice.actions