import { useState } from 'react';
import FormFloatingPassword from '../../../components/ui/form/FormFloatingPassword';
import { UserService } from '../../../core/services/user.service';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';

export default function SecurityTab() {
  const dispatch = useAppDispatch();
  const [changePasswordRequest, setChangePasswordRequest] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);

  const onChange = (event: any) => {
    changePasswordRequest[event.target.name] = event.target.value;
    setChangePasswordRequest({ ...changePasswordRequest });
  };

  const handleChangePassword = () => {
    setSubmitted(true);
    if (validate()) {
      return;
    }
    dispatch(showSpinner());
    UserService.changePassword(changePasswordRequest).finally(() => {
      setTimeout(() => {
        dispatch(hideSpinner());
      }, 500);
    }).then(res => {
      if (res.data.status) {
        toast.success("Thay đổi mật khẩu thành công");
      } else {
        toast.error(res.data.message);
      }
    })

  };

  const validate = () => {
    if (
      !changePasswordRequest.oldPassword ||
      changePasswordRequest.oldPassword?.trim().length <= 0 ||
      !changePasswordRequest.newPassword ||
      changePasswordRequest.newPassword?.trim().length <= 0 ||
      !changePasswordRequest.confirmNewPassword ||
      changePasswordRequest.confirmNewPassword?.trim().length <= 0 ||
      changePasswordRequest.newPassword != changePasswordRequest.confirmNewPassword
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="card mb-6">
        <h5 className="card-header">Thay đổi mật khẩu</h5>
        <div className="card-body pt-1">
          <form className="fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="mb-6 col-md-6 form-password-toggle fv-plugins-icon-container">
                <FormFloatingPassword
                  name="oldPassword"
                  value={changePasswordRequest.oldPassword}
                  onChange={onChange}
                  label="Mật khẩu hiện tại"
                  placeholder="* * * * * *"
                  isError={submitted && (!changePasswordRequest.oldPassword || changePasswordRequest.oldPassword?.trim().length <= 0)}
                  errMsg="Mật khẩu hiện tại trống"
                ></FormFloatingPassword>
              </div>
            </div>
            <div className="row g-5 mb-6">
              <div className="col-md-6 form-password-toggle fv-plugins-icon-container">
                <FormFloatingPassword
                  name="newPassword"
                  value={changePasswordRequest.newPassword}
                  onChange={onChange}
                  label="Mật khẩu mới"
                  placeholder="* * * * * *"
                  isError={submitted && (!changePasswordRequest.newPassword || changePasswordRequest.newPassword?.trim().length <= 0)}
                  errMsg="Mật khẩu mới tại trống"
                ></FormFloatingPassword>
              </div>
              <div className="col-md-6 form-password-toggle fv-plugins-icon-container">
                <FormFloatingPassword
                  name="confirmNewPassword"
                  value={changePasswordRequest.confirmNewPassword}
                  onChange={onChange}
                  label="Xác nhận mật khẩu mới"
                  placeholder="* * * * * *"
                  isError={submitted && (!changePasswordRequest.confirmNewPassword || changePasswordRequest.confirmNewPassword?.trim().length <= 0 || changePasswordRequest.confirmNewPassword != changePasswordRequest.newPassword)}
                ></FormFloatingPassword>
                {submitted && (!changePasswordRequest.confirmNewPassword || changePasswordRequest.confirmNewPassword?.trim().length <= 0) && <div className="error-feedback"> Xác nhận mật khẩu mới tại trống </div>}
                {submitted &&
                  changePasswordRequest.confirmNewPassword &&
                  changePasswordRequest.confirmNewPassword?.trim().length > 0 &&
                  changePasswordRequest.newPassword &&
                  changePasswordRequest.newPassword?.trim().length > 0 &&
                  changePasswordRequest.confirmNewPassword != changePasswordRequest.newPassword && <div className="error-feedback"> Xác nhận mật khẩu mới không trùng khớp </div>}
              </div>
            </div>
            <h6 className="text-body">Yêu cầu về mật khẩu:</h6>
            <ul className="ps-4 mb-0">
              <li className="mb-4">Tối thiểu 8 ký tự</li>
              <li className="mb-4">Ít nhất một ký tự chữ thường và một chữ in hoa</li>
              <li>Ít nhất một số, ký hiệu</li>
            </ul>
            <div className="mt-6">
              <button type="button" className="btn btn-primary me-3 waves-effect waves-light" onClick={handleChangePassword}>
                Lưu thay đổi
              </button>
              <button type="reset" className="btn btn-outline-secondary waves-effect">
                Đặt lại
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card mb-6">
        <div className="card-body">
          <h5 className="mb-6">Xác minh hai bước</h5>
          <p className="mb-4">Xác thực hai yếu tố chưa được bật.</p>
          <p className="w-75">
            Xác thực hai yếu tố bổ sung thêm một lớp bảo mật cho tài khoản của bạn bằng cách yêu cầu nhiều thông tin hơn là chỉ mật khẩu để đăng nhập.
            <a>Tìm hiểu thêm.</a>
          </p>
          <button className="btn btn-primary mt-2 waves-effect waves-light">Bật xác thực 2 yếu tố</button>
        </div>
      </div>
    </>
  );
}
