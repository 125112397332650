import { CommonConstant } from "../../core/constants/common.constant";

export default function ViewShipmentStatus({ status }: any) {
    return <>
        {status == CommonConstant.SHIPMENT_STATUS_EXPORT && (
            <span className="badge rounded-pill badge-order-export">Đã xuất kho</span>
        )}
        {status == CommonConstant.SHIPMENT_STATUS_CC && (
            <span className="badge rounded-pill badge-order-cc">Đã thông quan</span>
        )}
        {status == CommonConstant.SHIPMENT_STATUS_SUCC && (
            <span className="badge rounded-pill badge-order-complete">Đã giao hàng</span>
        )}
    </>
}