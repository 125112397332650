import { useSelector } from 'react-redux';
import { userInfoSelector } from '../../../libs/selectors/userSelector';
import FormFloating from '../../../components/ui/form/FormFloating';
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { UserService } from '../../../core/services/user.service';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import { setCurrentUserInfo } from '../../../libs/features/userInfoSlice';

const schema = yup
  .object({
    username: yup.string(),
    address: yup.string(),
    fullName: yup.string().required('Họ và tên là bắt buộc'),
    email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc'),
    gender: yup.boolean().required('Giới tính là bắt buộc'),
    dob: yup.date().required('Ngày sinh là bắt buộc'),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Số điện thoại chỉ bao gồm chữ số')
      .length(10, 'Số điện thoại phải có 10 chữ số')
  })
  .required();

export default function AccountTab() {

  const viewImgUrl = process.env.REACT_APP_API_VIEW_IMAGE;

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const dispatch = useAppDispatch();
  const currentUser = useSelector(userInfoSelector);
  const [gender, setGender] = useState<any>();
  const [avatar, setAvatar] = useState<any>();

  useEffect(() => {
    if (currentUser.userUid) {
      setDefaultValue();
    }
  }, [currentUser.userUid]);

  const setDefaultValue = () => {
    setValue('email', currentUser.email);
    setValue('fullName', currentUser.fullName);
    setValue('phoneNumber', currentUser.phoneNumber);
    if (currentUser.dob) {
      setValue('dob', new Date(currentUser.dob));
    }
    setValue('username', currentUser.username);
    setValue('address', currentUser.address);
    setValue('gender', currentUser.gender);

    setGender(currentUser.gender);
  };

  const onChangeGender = (event: any) => {
    setValue('gender', event.target.value);
    clearErrors('gender');
    setGender(event.target.value);
  };

  const onReset = () => {
    setDefaultValue();
  };

  const onSave = (data: any) => {
    dispatch(showSpinner());
    UserService.updateCurrentUser(data)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message);
          return;
        }
        dispatch(setCurrentUserInfo({ ...data }));
        toast.success('Cập nhật thông tin thành công');
      });
  };

  const onChangeAvatar = (event: any) => {
    const file = event.target.files[0];
    setAvatar(file);
    handleUploadAvatar(file);
  };

  const handleUploadAvatar = (file: any) => {
    dispatch(showSpinner());
    UserService.updateAvatar(file)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message);
          return;
        }
        let userTemp = Object.assign({}, currentUser);
        userTemp.imgUrl = res.data.responseData.atchFleSeq;
        dispatch(setCurrentUserInfo({ ...userTemp}));
        toast.success('Cập nhật thành công');
      });
  };

  return (
    <>
      <div className="card mb-6">
        <div className="card-body">
          <div className="d-flex align-items-start align-items-sm-center gap-6">
            {avatar && <img src={URL.createObjectURL(avatar)} alt="user-avatar" className="d-block w-px-100 h-px-100 rounded-4" />}
            {!avatar && <img src={`${viewImgUrl}/${currentUser.imgUrl}`} alt="user-avatar" className="d-block w-px-100 h-px-100 rounded-4" />}
            <div className="button-wrapper">
              <label htmlFor="uploadAvatar" className="btn btn-primary me-3 mb-4">
                <span className="d-none d-sm-block">Cập nhật ảnh đại diện</span>
                <i className="ri-upload-2-line d-block d-sm-none"></i>
                <input type="file" id="uploadAvatar" className="account-file-input" hidden accept="image/png, image/jpeg" onChange={onChangeAvatar} />
              </label>
              {/* <div>Allowed JPG, GIF or PNG. Max size of 800K</div> */}
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <form id="formAccountSettings">
            <div className="row mt-1 g-5">
              <div className="col-md-6">
                <FormFloating type="text" label="Tên tài khoản" placeholder="Tên tài khoản" {...register('username')} disabled />
              </div>
              <div className="col-md-6">
                <FormFloating type="text" label="Họ và tên" placeholder="Họ và tên" {...register('fullName')} isError={errors.fullName ? true : false} errMsg={errors.fullName?.message} required />
              </div>
              <div className="col-md-6">
                <FormFloating type="text" label="E-mail" placeholder="Email" {...register('email')} isError={errors.email ? true : false} errMsg={errors.email?.message} required></FormFloating>
              </div>
              <div className="col-md-6">
                <FormFloating type="text" label="Số điện thoại" placeholder="Số điện thoại" {...register('phoneNumber')} isError={errors.phoneNumber ? true : false} errMsg={errors.phoneNumber?.message} required></FormFloating>
              </div>
              <div className="col-md-6">
                <FormFloating type="date" label="Ngày sinh" placeholder="Ngày sinh" {...register('dob')} isError={errors.dob ? true : false} errMsg={errors.dob?.message} required></FormFloating>
              </div>
              <div className="col-md-6">
                <FormFloating type="text" label="Địa chỉ" placeholder="Địa chỉ" {...register('address')}></FormFloating>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-light fw-medium d-block">
                  Giới tính <span className="error-feedback"> * </span> {errors.gender ? <span className="error-feedback">{errors.gender?.message}</span> : ''}
                </div>
                <div className="form-check form-check-primary mt-4">
                  <input name="roleId" className="form-check-input" type="radio" value="0" id="radioMale" onChange={onChangeGender} checked={gender ? true : false} />
                  <label className="text-light fw-medium form-check-label" htmlFor="radioMale">
                    Nam
                  </label>
                </div>
                <div className="form-check form-check-primary mt-4">
                  <input name="roleId" className="form-check-input" type="radio" value="1" id="radioFemale" onChange={onChangeGender} checked={gender ? true : false} />
                  <label className="text-light fw-medium form-check-label" htmlFor="radioFemale">
                    Nữ
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button type="submit" className="btn btn-primary me-3" onClick={handleSubmit(onSave)}>
                Lưu thay đổi
              </button>
              <button type="reset" className="btn btn-outline-secondary" onClick={onReset}>
                Đặt lại
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="card">
        <h5 className="card-header mb-1">Xóa tài khoản</h5>
        <div className="card-body">
          <div className="mb-6 col-12 mb-0">
            <div className="alert alert-warning">
              <h6 className="alert-heading mb-1">Bạn có chắc chắn muốn xóa tài khoản của mình không?</h6>
              <p className="mb-0">Một khi bạn xóa tài khoản của mình, sẽ không có cách nào quay lại được nữa. Hãy chắc chắn.</p>
            </div>
          </div>
          <form id="formAccountDeactivation">
            <div className="form-check mb-6">
              <input className="form-check-input" type="checkbox" name="accountActivation" id="accountActivation" />
              <label className="form-check-label" htmlFor="accountActivation">
                Tôi xác nhận việc hủy kích hoạt tài khoản của tôi
              </label>
            </div>
            <button type="submit" className="btn btn-danger deactivate-account" disabled>
              Hủy kích hoạt tài khoản
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
