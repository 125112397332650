import TabPanel from '../../components/ui/tab/TabPanel';
import TabView from '../../components/ui/tab/TabView';
import AccountTab from './tabs/AccountTab';
import NotificationsTab from './tabs/NotificationsTab';
import SecurityTab from './tabs/SecurityTab';

export default function Setting() {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <TabView>
            <TabPanel header='Tài khoản' icon='ri-group-line' tabId='1'>
              <AccountTab></AccountTab>
            </TabPanel>
            <TabPanel header='Bảo mật' icon='ri-lock-line' tabId='2'>
              <SecurityTab></SecurityTab>
            </TabPanel>
            <TabPanel header='Thông báo' icon='ri-notification-4-line' tabId='3'>
              <NotificationsTab></NotificationsTab>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}
