import React, { useEffect, useState } from 'react';

type TabViewProps = {
  /**
   * Header of the card.
   */
  header?: React.ReactNode | undefined;
  /**
   * Footer of the card.
   */
  footer?: React.ReactNode | undefined;
  /**
   * Title of the card.
   */
  title?: React.ReactNode | undefined;
  /**
   * Secondary title of the card.
   */
  subTitle?: React.ReactNode | undefined;
  /**
   * Used to get the child elements of the component.
   * @readonly
   */
  children: React.ReactNode;
};

export default function TabView(props: TabViewProps) {
  const [tabActive, setTabActive] = useState<any>(1);
  const [childrenProps, setChildrenProps] = useState<any[]>([]);

  useEffect(() => {
    getChildrenProps();
  }, [props]);

  const onClickTab = (tabId: any) => {
    setTabActive(tabId);
  };

  const getChildrenProps = () => {
    let temps: any[] = [];
    React.Children.map(props.children, (child: any, idx: any) => {
      if (React.isValidElement(child)) {
        temps.push(child.props);
      }
    });
    setChildrenProps(temps);
  };

  return (
    <>
      <div className="nav-align-top">
        <ul className="nav nav-pills flex-column flex-md-row mb-6 gap-2 gap-lg-0">
          {childrenProps?.map((ele, idx) => (
            <li key={idx} className="nav-item">
              <a className={'nav-link waves-effect waves-light' + (ele.tabId == tabActive ? ' active' : '')} onClick={() => onClickTab(ele.tabId)}>
                {ele.icon && <i className={'tf-icons me-2 ' + ele.icon}></i>} {ele.header}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content" style={{padding: '0px', borderRadius: '0px'}}>{childrenProps?.map((ele, idx) => <div key={idx} className={'tab-pane fade' + (ele.tabId == tabActive ? ' show active' : '')}>{ele.children}</div>)}</div>
      {/* {childrenProps?.map((ele, idx) => <div className={'tab-pane fade' + (ele.tabId == tabActive ? ' show active' : '')}>{ele.children}</div>)} */}
    </>
  );
}
