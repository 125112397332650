import FormFloating from '../../components/ui/form/FormFloating';
import FormFloatingTextArea from '../../components/ui/form/FormFloatingTextArea';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { UserService } from '../../core/services/user.service';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { OrderService } from '../../core/services/order.service';
import { useNavigate } from 'react-router-dom';
import UploadFile from '../../components/ui/UploadFile';
import { toast } from 'react-toastify';

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

const schema = yup
  .object({
    orderId: yup.string().required("Mã đơn hàng là bắt buộc"),
    // customerId: yup.string().required("Khách hàng là bắt buộc"),
    // sellerId: yup.string().required("Nhân viên bán là bắt buộc"),
    recipientPhoneNumber: yup.string().required("Số điện thoại là bắt buộc").length(10, "Số điện thoại có gồm 10 số"),
    address: yup.string().required("Điểm đến là bắt buộc").length(2, "Điểm đến chỉ gồm 2 kí tự"),
    note: yup.string(),
    // productType: yup.string().required("Loại hàng hóa là bắt buộc"),
    numberParcel: yup.string().required("Số kiện là bắt buộc"),
    // size: yup.string(),
    // weight: yup.string(),
  })
  .required();

export default function AddOrder() {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(schema)
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [fileUploads, setFileUploads] = useState<any>([]);

  useEffect(() => {
    dispatch(showSpinner());
    initData();
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  }, []);

  const initData = async () => {
    await Promise.all([getSellers(), getCustomers()]);
  };

  const getSellers = async () => {
    await UserService.getAllSeller().then((res) => {
      let data = res.data;
      console.log(data);
      data.unshift({fullName: '---', username: null});
      console.log(data);

      setSellers(data);
    });
  };

  const getCustomers = async () => {
    await UserService.getAllCustomer().then((res) => {
      let data = res.data;
      data.unshift({fullName: '---', username: null});
      setCustomers(data);
    });
  };

  const onCancel = () => {
    navigate('/order-mgt');
  };

  const onSubmit = async (event: any) => {
    dispatch(showSpinner());
    // await FileService.multiFileUpload(files).then(res => {
    //   console.log(res);
    // }, error => {
    //   dispatch(hideSpinner());
    // })
    if (!fileUploads || fileUploads.length <= 0) {
      toast("Hình ảnh nhập kho còn thiếu");
      return;
    }

    OrderService.addOrder(event, fileUploads)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          navigate('/order-mgt');
        }
      });
  };

  const onChangeSellerOrCustomer = (event: any, field: string) => {
    setValue(field, event?.username);
    clearErrors(field);
  };

  const onChangeFile = (event: any) => {
    setFileUploads(event.filesToAdd);
  };

  return (
    <>
      <div className="app-ecommerce">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">Nhập kho</h4>
            {/* <p className="mb-0">Orders placed across your store</p> */}
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={onCancel}>
              Hủy
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
              Lưu
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin chung</h5>
              </div>
              <div className="card-body">
              <div className="row mb-5 gx-5">
                  <div className="col">
                  <FormFloating
                      type="text"
                      label="Mã đơn hàng"
                      placeholder="Mã đơn hàng"
                      {...register('orderId')}
                      isError={errors.orderId ? true : false}
                      errMsg={errors.orderId?.message}
                    ></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                
                  <div className="col">
                    <FormFloatingSelect
                      label="Khách hàng"
                      sources={customers}
                      bindLabel="fullName"
                      defaultValue={null}
                      bindValue="username"
                      {...register('customerId')}
                      onChange={(event) => onChangeSellerOrCustomer(event, 'customerId')}
                      // isError={errors.customerId ? true : false}
                      // errMsg={errors.customerId?.message}
                    ></FormFloatingSelect>
                  </div>
                  <div className="col">
                    {/* <FormFloating label="Mã vận đơn" placeholder="Mã vận đơn" {...register('waybillCode', { required: true })} isError={errors.waybillCode ? true : false} errMsg="Mã vận đơn là bắt buộc"></FormFloating> */}
                    <FormFloatingSelect
                      label="Nhân viên bán hàng"
                      sources={sellers}
                      bindLabel="fullName"
                      defaultValue={null}
                      bindValue="username"
                      {...register('sellerId')}
                      onChange={(event) => onChangeSellerOrCustomer(event, 'sellerId')}
                      // isError={errors.sellerId ? true : false}
                      // errMsg={errors.sellerId?.message}
                    ></FormFloatingSelect>
                  </div>
                </div>

                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="number"
                      label="SĐT người nhận"
                      placeholder="SĐT người nhận"
                      {...register('recipientPhoneNumber')}
                      isError={errors.recipientPhoneNumber ? true : false}
                      errMsg={errors.recipientPhoneNumber?.message}
                      maxLength={10}
                    ></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating maxLength={2} minLength={2} type="text" label="Điểm đến" placeholder="Điểm đến" {...register('address')} isError={errors.address ? true : false} errMsg={errors.address?.message}></FormFloating>
                  </div>
                </div>

                <FormFloatingTextArea className="mb-5" label="Chú thích" placeholder="Chú thích" {...register('note')} />
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0 card-title">Hình ảnh đơn hàng</h5>
              </div>
              <div className="card-body">
                <UploadFile onChange={onChangeFile} multiple></UploadFile>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-title mb-0">Thông tin hàng hóa</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating label="Loại hàng hóa" placeholder="Loại hàng hóa" {...register('productType')}></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating type="number" label="Số kiện" placeholder="Số kiện" {...register('numberParcel')} isError={errors.numberParcel ? true : false} errMsg={errors.numberParcel?.message}></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating label={'Số khối (m\u00B3)'} placeholder={'Số khối (m\u00B3)'} {...register('size')} type="number"></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating label="Số cân (kg)" placeholder="Số cân (kg)" {...register('weight')} type="number"></FormFloating>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};