type TabPanelProps = {
    /**
     * Text of the button.
     */
    header?: string | undefined;
    /**
     * Name of the icon or JSX.Element for icon.
     */
    icon?: string | undefined;
    /**
     * Position of the icon, valid values are "left", "right".
     * @defaultValue left
     */
    iconPos?: string;
    
    tabId: string;
    /**
     * Used to get the child elements of the component.
     * @readonly
     */
    children?: React.ReactNode | undefined;

};

export default function TabPanel(props: TabPanelProps) {
    return (
        <></>
    );
}