import { forwardRef, useId, useState } from 'react';

type FormFloatingPasswordProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: string;
  required?: boolean | undefined;
};

const FormFloatingPassword = forwardRef<HTMLInputElement, FormFloatingPasswordProps>(({ required, errMsg, isError, label, className, ...restProps }, ref) => {
  const inputId = useId();

  const [isShowAndHidePassword, setIsShowAndHidePassword] = useState<boolean>(false);

  const showAndHidePassword = () => {
    setIsShowAndHidePassword(!isShowAndHidePassword);
  };

  return (
    <div className={'input-group input-group-merge' + (className ? className : '')}>
      <div className="form-floating form-floating-outline">
        <input type={isShowAndHidePassword ? 'text' : 'password'} {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} />
        <label htmlFor={inputId}>{label} {required && <span className='error-feedback'> *</span>}</label>
      </div>
      <span className="input-group-text cursor-pointer" onClick={showAndHidePassword}>
        <i className={'' + (isShowAndHidePassword ? 'ri-eye-line' : 'ri-eye-off-line')}></i>
      </span>
      {isError && <div className="error-feedback"> {errMsg} </div>}
    </div>
  );
});
export default FormFloatingPassword;
