import AuthGuard from "../core/guard/AuthGuard";
import Layout from "../layout/Layout";
import Dashboard from "../pages/dashboard/Dashboard";
import GoodsMgt from "../pages/goods-mgt/GoodsMgt";
import InvMgt from "../pages/inv-mgt/InvMgt";
import AddOrder from "../pages/order-mgt/AddOrder";
import EditOrder from "../pages/order-mgt/EditOrder";
import ExportTracking from "../pages/order-mgt/ExportTracking";
import OrderDetail from "../pages/order-mgt/OrderDetail";
import OrderMgt from "../pages/order-mgt/OrderMgt";
import Setting from "../pages/setting/Setting";
import UserMgt from "../pages/user-mgt/UserMgt";

export const layoutRouter: any = {
  path: "/",
  element: (
    <AuthGuard>
      <Layout />
    </AuthGuard>
  ),
  children: [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/order-mgt", element: <OrderMgt /> },
    { path: "/order-mgt/detail/:id", element: <OrderDetail /> },
    { path: "/order-mgt/export/:id", element: <ExportTracking /> },
    { path: "/user-mgt", element: <UserMgt /> },
    { path: "/goods-mgt", element: <GoodsMgt /> },
    { path: "/add-order", element: <AddOrder /> },
    { path: "/edit-order/:orderId", element: <EditOrder /> },
    { path: "/setting", element: <Setting/> },
    { path: "/inv-mgt", element: <InvMgt/> },
  ],
};
