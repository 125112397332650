import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderService } from '../../core/services/order.service';
import moment from 'moment';
import { CommonConstant } from '../../core/constants/common.constant';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import AppModel from '../../components/ui/AppModel';
import CCForm from './components/CCForm';
import SuccForm from './components/SuccForm';
import { toast } from 'react-toastify';
import ViewOrderStatus from '../../components/common/ViewOrderStatus';
import ViewShipmentStatus from '../../components/common/ViewShipmentStatus';
import NumberFormat from '../../components/common/NumberFormat';
import Currency from '../../components/common/Currency';
import ShipmentDetail from './components/ShipmentDetail';

export default function OrderDetail() {
  const url = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [isOpenModalCCForm, setIsOpenModalCCForm] = useState<boolean>(false);
  const [isOpenModalSuccForm, setIsOpenModalSuccForm] = useState<boolean>(false);
  const [isOpenModalShipmentDetail, setIsOpenModalShipmentDetail] = useState<boolean>(false);
  const [ccShipment, setCcShipment] = useState<any>({});
  const [succShipment, setsuccShipment] = useState<any>({});
  const [shipmentDetail, setShipmentDetail] = useState<any>({});
  const [totalNumberParcelExported, setTotalNumberParcelExported] = useState<number>(0);
  const [timelines, setTimeLines] = useState<any[]>([]);

  useEffect(() => {
    getOrder();
  }, [id]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail(res.data.responseData);
        convertData(res.data.responseData);
      });
  };

  const convertData = (data: any) => {
    let shipmentTrackingTemps: any[] = [];
    let totalNumberPercelTemp = 0;
    let totalShipmentCompletedTemp = 0;
    data.shipments?.forEach((shipment: any, idx: any) => {
      let trackingTemps = shipment.trackings?.map((ele: any) => {
        ele.shipmentInfo = shipment;
        return ele;
      });
      if (shipment.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
        totalShipmentCompletedTemp++;
      }
      shipmentTrackingTemps = [...shipmentTrackingTemps, ...trackingTemps];
      totalNumberPercelTemp += shipment.numberParcel;
    });

    shipmentTrackingTemps.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    // let numberParcelExported = 0;
    // let numberParcelCc = 0;
    // let numberParcelSucc = 0;
    // const timelineTemps = shipmentTrackingTemps.map((item) => {
    //   if (item.status == CommonConstant.TRACKING_STATUS_EXPORT) {
    //     numberParcelExported += item.shipmentInfo.numberParcel ? item.shipmentInfo.numberParcel : 0;
    //   }
    //   if (item.status == CommonConstant.TRACKING_STATUS_CC) {
    //     numberParcelCc += item.shipmentInfo.numberParcel ? item.shipmentInfo.numberParcel : 0;
    //   }
    //   if (item.status == CommonConstant.TRACKING_STATUS_SUCC) {
    //     numberParcelSucc += item.shipmentInfo.numberParcel ? item.shipmentInfo.numberParcel : 0;
    //   }

    //   return {
    //     createdDate: item.createdDate,
    //     numberParcelExported: numberParcelExported,
    //     numberParcelCc: numberParcelCc,
    //     numberParcelSucc: numberParcelSucc,
    //     trackingImages: item.trackingImages
    //   };
    // });
    setTimeLines(shipmentTrackingTemps);
    setTotalNumberParcelExported(totalNumberPercelTemp);
  };

  const onNavigatelExport = () => {
    navigate(`/order-mgt/export/${id}`);
  };

  const onDelete = () => {
    dispatch(showSpinner());
    OrderService.delete(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail({ status: CommonConstant.ORDER_STATUS_CANCELED, ...orderDetail });
      });
  };

  const onOpenModalCCForm = (shipment: any) => {
    setIsOpenModalCCForm(true);
    setCcShipment(shipment);
  };

  const onSubmitCC = () => {
    setIsOpenModalCCForm(false);
    getOrder();
  };

  const onOpenModalSuccForm = (shipment: any) => {
    setIsOpenModalSuccForm(true);
    setsuccShipment(shipment);
  };

  const onOpenModalShipemtDetail = (shipment: any) => {
    setIsOpenModalShipmentDetail(true);
    setShipmentDetail(shipment);
  };

  const onSubmitSucc = () => {
    setIsOpenModalSuccForm(false);
    getOrder();
  };

  const countShipment = (status: any) => {
    let countExport = 0;
    let countCC = 0;
    let countSucc = 0;
    orderDetail.shipments?.forEach((element: any) => {
      if (element.status == CommonConstant.SHIPMENT_STATUS_EXPORT) countExport++;
      if (element.status == CommonConstant.SHIPMENT_STATUS_CC) countCC++;
      if (element.status == CommonConstant.SHIPMENT_STATUS_SUCC) countSucc++;
    });

    if (status == CommonConstant.SHIPMENT_STATUS_CC) {
      return orderDetail.shipments.length - countExport;
    }
    if (status == CommonConstant.SHIPMENT_STATUS_SUCC) {
      return orderDetail.shipments.length - countExport - countCC;
    }
    return 0;
  };

  const onEdit = () => {
    if (orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT) {
      navigate(`/edit-order/${orderDetail.orderId}`);
    }
  };

  const openModal = (item: any) => {
    if (item.status == CommonConstant.SHIPMENT_STATUS_EXPORT) {
      onOpenModalCCForm(item);
    }
    if (item.status == CommonConstant.SHIPMENT_STATUS_CC) {
      onOpenModalSuccForm(item);
    }

    if (item.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
      onOpenModalShipemtDetail(item);
    }
  };

  const onEditShipment = (event: any, shipment: any) => {
    event.stopPropagation();
    // if (shipment.status == CommonConstant.SHIPMENT_STATUS_EXPORT) {
    //   navigate(`/order-mgt/export/${orderDetail.orderId}?id=${shipment.id}`);
    // }
    navigate(`/order-mgt/export/${orderDetail.orderId}?id=${shipment.id}`);
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 gap-6">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-1">
            <h5 className="mb-0">
              Đơn hàng #<span className="text-primary">{orderDetail.orderId}</span>
            </h5>

            <ViewOrderStatus orderDetail={orderDetail}></ViewOrderStatus>
            {orderDetail.status != CommonConstant.ORDER_STATUS_CANCELED && orderDetail.status != CommonConstant.ORDER_STATUS_SUCC && (
              <div className="dropdown">
                <button className="btn rounded-pill border-0 p-1 waves-effect waves-light show" type="button" id="popularInstructors" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-settings-4-fill ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="popularInstructors" data-popper-placement="top-end">
                  {orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT && (
                    <a className="dropdown-item waves-effect cursor-pointer" onClick={onEdit}>
                      Sửa đổi
                    </a>
                  )}
                  <a className="dropdown-item waves-effect cursor-pointer" onClick={onDelete}>
                    Huỷ đơn hàng
                  </a>
                </div>
              </div>
            )}
            {orderDetail.warningYn && (
              <span className="ms-2  text-warning fst-italic">
                {/*<i className="ri-error-warning-line fs-5"></i>*/}
                <span>** Cảnh báo: {orderDetail.warningMsg}!</span>
                {/*<i data-tooltip-id="my-tooltip" data-tooltip-content={orderDetail.warningMsg}*/}
                {/*   className="fs-5 text-warning ri-error-warning-line"></i>*/}
                {/*<Tooltip id="my-tooltip" style={{ backgroundColor: '#fff3dd', color: '#fdb528', zIndex: '9999' }} />*/}
              </span>
            )}
          </div>
          <p className="mb-0 fst-italic">{moment(orderDetail.createdDate).format('YYYY/MM/DD HH:mm')}</p>
        </div>

        <div className="d-flex align-content-center flex-wrap gap-2">
          <button
            className="btn btn-cancel"
            onClick={() => {
              navigate('/order-mgt');
            }}
          >
            Quay lại
          </button>
          {orderDetail.numberParcel > totalNumberParcelExported && (
            <button className="btn btn-primary" onClick={onNavigatelExport}>
              Xuất kho
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-7">
          <div className="card mb-6">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title m-0">Thông tin chung</h5>
              {/* <h6 className="m-0">
                <a className="cursor-pointer">Sửa đổi</a>
              </h6> */}
            </div>
            <div className="card-body ">
              <div className="pb-3 d-flex justify-content-between field-info">
                <div>Mã đơn hàng</div>
                <div>
                  {orderDetail.orderId}{' '}
                  <a
                    onClick={() => {
                      navigator.clipboard.writeText(orderDetail.orderId);
                      toast.success('Đã copy!');
                    }}
                    className="cursor-pointer"
                  >
                    <i className="ri-file-copy-2-line"></i>
                  </a>
                </div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>Mã khách hàng</div>
                <div>{orderDetail.customerInfo?.username}</div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>Seller</div>
                <div>{orderDetail.sellerInfo?.fullName}</div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>SĐT nhận</div>
                <div>
                  {orderDetail.recipientPhoneNumber}{' '}
                  <a
                    onClick={() => {
                      navigator.clipboard.writeText(orderDetail.recipientPhoneNumber);
                      toast.success('Đã copy!');
                    }}
                    className="cursor-pointer"
                  >
                    <i className="ri-file-copy-2-line"></i>
                  </a>
                </div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>Điểm đến</div>
                <div>{orderDetail.address}</div>
              </div>
              {orderDetail.note && (
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Note</div>
                  <div>{orderDetail.note}</div>
                </div>
              )}
            </div>
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <h5 className="card-title m-0">Trạng thái đơn hàng</h5>
            </div>
            <div className="card-body mt-3">
              <ul className="timeline pb-0 mb-0">
                {orderDetail.trackings?.map((tracking: any, idx: any) => (
                  <React.Fragment key={idx}>
                    {idx == 0 && (
                      // <li className={'timeline-item timeline-item-transparent border-left-dashed' + (orderDetail.trackings.length - 1 == idx ? 'border-transparent pb-0' : 'border-left-dashed')}>
                      <li className={'timeline-item timeline-item-transparent ' + (timelines && timelines.length > 0 ? 'border-left-dashed' : 'border-transparent pb-0')}>
                        <span className="timeline-point timeline-point-primary"></span>
                        <div className="timeline-event">
                          <div className="timeline-header mb-2">
                            {tracking.status == CommonConstant.TRACKING_STATUS_IMPORT && <h6 className="mb-0">Đã nhập hàng (Mã đơn hàng: #{orderDetail.orderId})</h6>}
                            {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && <h6 className="mb-0">Đã xuất kho</h6>}
                            {tracking.status == CommonConstant.TRACKING_STATUS_CC && <h6 className="mb-0">Đã thông quan</h6>}
                            {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && <h6 className="mb-0">Giao thành công</h6>}
                            <small className="text-muted">{moment(tracking.createdDate).format('YYYY/MM/DD HH:mm')}</small>
                          </div>
                          {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && <p className="mt-1 mb-2">Đã xuất {orderDetail.shipments?.length} xe</p>}
                          {tracking.status == CommonConstant.TRACKING_STATUS_CC && (
                            <p className="mt-1 mb-2">
                              Đã thông quan {countShipment(CommonConstant.SHIPMENT_STATUS_CC)}/{orderDetail.shipments?.length} xe
                            </p>
                          )}
                          {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && (
                            <p className="mt-1 mb-2">
                              Đã giao hàng thành công {countShipment(CommonConstant.SHIPMENT_STATUS_SUCC)}/{orderDetail.shipments?.length} xe
                            </p>
                          )}
                          <div className="timeline-image">
                            <div className="d-flex">
                              {tracking.trackingImages.map((image: any, idx: any) => (
                                <React.Fragment key={idx}>
                                  <div className="avatar avatar-xl me-3">
                                    <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="Avatar" className="rounded-3" />
                                  </div>
                                  {/* <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="" style={w}/> */}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                ))}
                {timelines?.map((tracking, idx) => (
                  <li key={idx} className={'timeline-item timeline-item-transparent ' + (timelines.length - 1 == idx && orderDetail.status != CommonConstant.ORDER_STATUS_SUCC ? 'border-transparent pb-0' : 'border-left-dashed')}>
                    {/* <li key={idx} className={'timeline-item timeline-item-transparent border-left-dashed'}> */}
                    <span className="timeline-point timeline-point-primary"></span>
                    <div className="timeline-event">
                      <div className="timeline-header mb-2">
                        <h6 className="mb-0">
                          {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && (
                            <>
                              Đã xuất {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện xe {tracking.shipmentInfo.licensePlate}
                            </>
                          )}
                          {tracking.status == CommonConstant.TRACKING_STATUS_CC && (
                            <>
                              Đã thông quan {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện xe {tracking.shipmentInfo.licensePlate}
                            </>
                          )}
                          {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && (
                            <>
                              Đã giao thành công {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện xe {tracking.shipmentInfo.licensePlate}
                            </>
                          )}
                        </h6>
                        <small className="text-muted">{moment(tracking.createdDate).format('YYYY/MM/DD HH:mm')}</small>
                      </div>
                      <div className="timeline-image">
                        <div className="d-flex">
                          {tracking.trackingImages?.map((image: any, idx: any) => (
                            <React.Fragment key={idx}>
                              <div className="avatar avatar-xl me-3">
                                <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="Avatar" className="rounded-3" />
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
                {orderDetail.status == CommonConstant.ORDER_STATUS_SUCC && (
                  <li className="timeline-item timeline-item-transparent border-transparent pb-0">
                    <span className="timeline-point timeline-point-primary"></span>
                    <div className="timeline-event pb-0">
                      <div className="timeline-header mb-2">
                        <h6 className="mb-0">Giao hàng thành công</h6>
                      </div>
                      {/* <p className="mt-1 mb-2">Đang chờ xuất kho</p> */}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div className="card mb-6">
            <div className="card-body">
              <h5 className="card-title mb-6">Thông tin hàng hóa</h5>
              <div>
                <div className="pb-3 d-flex justify-content-between field-info">
                  <div>Số cân</div>
                  <div>{orderDetail.weight ? <><NumberFormat value={orderDetail.weight}></NumberFormat> kg</> : '--'}</div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Số khối</div>
                  <div>
                    {orderDetail.size ? <><NumberFormat value={orderDetail.size}></NumberFormat> m<sup>3</sup></> : '--'}
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Số kiện</div>
                  <div>
                    <NumberFormat value={orderDetail.numberParcel}></NumberFormat>
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Loại hàng hóa</div>
                  <div>{orderDetail.productType}</div>
                </div>
                {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && (
                  <>
                    <div className="mt-3 d-flex justify-content-between field-info">
                      <div>Cước vận chuyển</div>
                      <div className="fw-semibold">
                        <Currency price={orderDetail.shippingFee}></Currency>
                      </div>
                    </div>
                    <div className="py-3 border-bottom">
                      <div>Cước phụ</div>
                      <div className="ps-10 mt-2">
                        <div className="row">
                          <div className="cols-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between field-info">
                              <div>Cước nội địa</div>
                              <div>
                                <Currency price={orderDetail.domesticFee}></Currency>
                              </div>
                            </div>
                          </div>
                          <div className="cols-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between field-info">
                              <div>Kéo xe</div>
                              <div>
                                <Currency price={orderDetail.towingFee}></Currency>
                              </div>
                            </div>
                          </div>
                          <div className="cols-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between field-info">
                              <div>Xe nâng</div>
                              <div>
                                <Currency price={orderDetail.forkliftFee}></Currency>
                              </div>
                            </div>
                          </div>
                          <div className="cols-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between field-info">
                              <div>Đóng gỗ</div>
                              <div>
                                <Currency price={orderDetail.woodenFee}></Currency>
                              </div>
                            </div>
                          </div>
                          <div className="cols-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between field-info">
                              <div>Thanh toán hộ</div>
                              <div>
                                <Currency price={orderDetail.payOnHehalf}></Currency>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 d-flex justify-content-between field-info ">
                      <div className="fs-5 fw-semibold">Tổng tiền</div>
                      <div className="text-danger fw-semibold">
                        <Currency price={orderDetail.totalFee}></Currency>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between field-info">
                      <div>Số tiền cần thanh toán</div>
                      <div className="fw-semibold">
                        <Currency price={orderDetail.payAmount ? orderDetail.payAmount : 0}></Currency>
                      </div>
                    </div>
                    {/* <div className="pb-3 d-flex justify-content-between field-info border-bottom">
                      <div>Tổng tiền</div>
                      <div className="fw-semibold">
                      <Currency price={orderDetail.totalFee}></Currency>
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-between field-info">
                      <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
                      <div className="text-danger fw-semibold">
                        <Currency price={orderDetail.payAmount}></Currency>
                      </div>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
          {orderDetail.shipments?.length > 0 && (
            <div className="card mb-6">
              <div className="card-body">
                <h5 className="card-title mb-3">Thông tin vận chuyển</h5>

                <div className="accordion ps-0 pt-0">
                  {orderDetail.shipments?.map((item: any, idx: any) => (
                    <div key={idx} className="accordion-item shadow-none border-0 my-0 py-2" onClick={(event) => openModal(item)}>
                      <div className="accordion-header border-0">
                        <div className="pe-0 shadow-none collapsed">
                          <div className="d-flex align-items-center" style={{ width: '100%' }}>
                            <div className="avatar-wrapper">
                              <div className="avatar me-4">
                                <span className="avatar-initial rounded-circle bg-label-secondary">
                                  <i className="ri-car-line ri-24px"></i>
                                </span>
                              </div>
                            </div>
                            <div className="fw-normal" style={{ width: '100%' }}>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="d-flex align-items-center text-heading">
                                  <div className="fw-semibold">{item.licensePlate}</div>
                                  {/* <span className="text-body px-4">
                                  {item.driverName} - {item.driverPhoneNumber}
                                </span> */}
                                  <div className="ms-2">
                                    <ViewShipmentStatus status={item.status}></ViewShipmentStatus>
                                  </div>
                                </div>
                                {/* {item.status == CommonConstant.SHIPMENT_STATUS_EXPORT && ( */}
                                <div className="dropdown">
                                  <button
                                    onClick={(event) => event.stopPropagation()}
                                    className="btn btn-text-secondary rounded-pill text-muted border-0 p-1 waves-effect waves-light"
                                    type="button"
                                    id="organicSessionsDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="ri-more-2-line ri-20px"></i>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end" aria-labelledby="organicSessionsDropdown">
                                    {/* <a className="dropdown-item waves-effect" onClick={(event) => openModal(item)}>Thông tin chi tiết</a> */}
                                    <a className="dropdown-item waves-effect" onClick={(event) => onEditShipment(event, item)}>
                                      Sửa đổi
                                    </a>
                                    {/* <a className="dropdown-item waves-effect" href="javascript:void(0);">Last Year</a> */}
                                  </div>
                                </div>
                                {/* )} */}
                              </div>
                              <div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số cân</span>
                                  <span className="fw-semibold">
                                    {item.weight ? <><NumberFormat value={item.weight}></NumberFormat> kg</> : '--'}
                                  </span>
                                </div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số khối</span>
                                  <span className="fw-semibold">
                                    {item.size ? <><NumberFormat value={item.size}></NumberFormat> m<sup>3</sup></> : '--'}
                                  </span>
                                </div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số kiện</span>
                                  <span className="fw-semibold">
                                    {item.numberParcel ? <NumberFormat value={item.numberParcel}></NumberFormat> : '--'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AppModel isShow={isOpenModalCCForm} onClose={() => setIsOpenModalCCForm(false)} title="Thông tin thông quan" size="lg">
        <CCForm orderDetail={orderDetail} shipment={ccShipment} onCancel={() => setIsOpenModalCCForm(false)} onSubmit={onSubmitCC}></CCForm>
      </AppModel>

      <AppModel isShow={isOpenModalSuccForm} onClose={() => setIsOpenModalSuccForm(false)} title="Thông tin giao hàng" size="lg">
        <SuccForm orderDetail={orderDetail} shipment={succShipment} onCancel={() => setIsOpenModalSuccForm(false)} onSubmit={onSubmitSucc}></SuccForm>
      </AppModel>

      <AppModel isShow={isOpenModalShipmentDetail} onClose={() => setIsOpenModalShipmentDetail(false)} title="Thông tin giao hàng" size="lg">
        <ShipmentDetail orderDetail={orderDetail} shipment={shipmentDetail}></ShipmentDetail>
      </AppModel>
    </>
  );
}
