import React, { useEffect, useState } from 'react';

type AppModelProps = {
  title?: string | undefined;

  showHeader?: boolean | undefined;

  footer?: React.ReactNode | undefined;

  size: 'sm' | 'lg' | 'xl' | 'full';

  isShow: boolean;

  onClose: (event?: any) => void;

  children?: React.ReactNode | undefined;
};

const SizeModal: Record<string, string> = {
  'sm': 'modal-sm',
  'lg': 'modal-lg',
  'xl': 'modal-xl',
  'full': 'modal-fullscreen',
};

export default function AppModel(props: AppModelProps) {
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    setIsShow(props.isShow);
  }, [props.isShow]);

  const onClose = () => {
    setIsShow(false);
    props.onClose(false);
  };
  return (
    <>
      {isShow && (
        <>
          <div className="modal fade animate__animated fadeIn show" style={{ display: 'block' }}>
            <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable " + SizeModal[props.size]}>
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">{props.title}</h4>
                  <button type="button" className="btn-close" onClick={onClose}></button>
                </div>
                {props.children}
                {/* <div className="modal-body">{props.children}</div> */}
                {props.footer && <div className="modal-footer">{props.footer}</div>}
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
}
