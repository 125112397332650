import { Outlet } from 'react-router-dom';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import RightSidebar from './RightSidebar';
import { useEffect } from 'react';
import { useAppDispatch } from '../libs/hook';
import { AuthenticationService } from '../core/services/common/authentication.service';
import { setCurrentUserInfo } from '../libs/features/userInfoSlice';

export default function Layout() {

  const dispatch = useAppDispatch();

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const getCurrentUserInfo = () => {
    AuthenticationService.getCurrentUserInfo().then(res => {
      dispatch(setCurrentUserInfo(res.data));
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      var e = document.querySelector('.layout-page');
      e && (0 < window.pageYOffset ? e.classList.add('window-scrolled') : e.classList.remove('window-scrolled'));
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
          <RightSidebar></RightSidebar>

          <div className="layout-page">
            <AppHeader></AppHeader>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <Outlet />
              </div>

              <AppFooter></AppFooter>

              {/* <div className="content-backdrop fade"></div> */}
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <div className="drag-target"></div> */}
      </div>
    </>
  );
}
