export default function AppFooter() {
  const currentDate = new Date();

  return (
    <footer className="content-footer footer bg-footer-theme">
      {/* <div className="container-xxl">
        <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
          <div className="text-body mb-2 mb-md-0">
            © {currentDate.getFullYear()}
            
          </div>
          
        </div>
      </div> */}
    </footer>
  );
}
