
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {}
};

export const userInfoSlice = createSlice({
    name: 'currentUserInfo',
    initialState,
    reducers: {
        clearUserInfo: () => initialState,
        setCurrentUserInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    }
});

export const { setCurrentUserInfo, clearUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;