import axios from 'axios';

export const ShipmentService = {

  
  ccShipment(ccRequest: any) {
    // const formData: FormData = new FormData();
    // for (let i = 0; i < files?.length; i++) {
    //   formData.append('multiparts', files[i]);
    // }
    // formData.append('shipmentJson', JSON.stringify(ccRequest));
    const url = process.env.REACT_APP_API_URL + '/shipment/cc-shipment';
    return axios.post(url, ccRequest);
  },

  succShipment(ccRequest: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('shipmentJson', JSON.stringify(ccRequest));
    const url = process.env.REACT_APP_API_URL + '/shipment/succ-shipment';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  exportShipment(request: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/export-shipment';
    return axios.post(url, request)
  },

  getByIdAndOrderId(shipmentId: any, orderId: any) {
    const url = process.env.REACT_APP_API_URL + `/shipment/get-by-id-and-orderid?shipmentId=${shipmentId}&orderId=${orderId}`;
    return axios.post(url);
  },

  editExportShipment(request: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/edit-export-shipment';
    return axios.post(url, request)
  }
};
