import axios from 'axios';

export const OrderService = {
  getByPage(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/get-by-page';
    return axios.post(url, request);
  },

  addOrder(order: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('jsonOrderDTO', JSON.stringify(order));
    const url = process.env.REACT_APP_API_URL + '/order/add-order';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  editOrder(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/edit-order';
    return axios.post(url, request);
  },

  getOrderById(id: any) {
    const url = process.env.REACT_APP_API_URL + `/order/get-by-id/${id}`;
    return axios.get(url);
  },

  // exportOrder(order: any, shipments: any[]) {
  //   const formData: FormData = new FormData();
  //   for (let i = 0; i < shipments.length; i++) {
  //     for (let idx = 0; idx < shipments[i].files?.length; idx++) {
  //       formData.append(`multipart-${i}-${idx}`, shipments[i].files[idx], `${i}`);
  //     }
  //   }
  //   formData.append('jsonExportInfo', JSON.stringify(order));
  //   const url = process.env.REACT_APP_API_URL + '/order/export-order2';
  //   return axios.post(url, formData, {
  //     headers: {
  //       'Content-Type': 'multi-part/formdata'
  //     }
  //   });
  // },

  delete(orderId: any) {
    const url = process.env.REACT_APP_API_URL + `/order/delete?orderId=${orderId}`;
    return axios.get(url);
  },

  // ccOrder(ccRequest: any, files: any) {
  //   const formData: FormData = new FormData();
  //   for (let i = 0; i < files?.length; i++) {
  //     formData.append('multiparts', files[i]);
  //   }
  //   formData.append('jsonCcRequest', JSON.stringify(ccRequest));
  //   const url = process.env.REACT_APP_API_URL + '/order/cc-order';
  //   return axios.post(url, formData, {
  //     headers: {
  //       'Content-Type': 'multi-part/formdata'
  //     }
  //   });
  // },

  // succOrder(ccRequest: any, files: any) {
  //   const formData: FormData = new FormData();
  //   for (let i = 0; i < files?.length; i++) {
  //     formData.append('multiparts', files[i]);
  //   }
  //   formData.append('jsonSuccRequest', JSON.stringify(ccRequest));
  //   const url = process.env.REACT_APP_API_URL + '/order/succ-order';
  //   return axios.post(url, formData, {
  //     headers: {
  //       'Content-Type': 'multi-part/formdata'
  //     }
  //   });
  // },

  importByFile(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = process.env.REACT_APP_API_URL + '/order/import-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  createOrUpdateFormFile(excel: any, images: any) {
    const formData: FormData = new FormData();
    formData.append('file', excel);
    for (let i = 0; i < images?.length; i++) {
      formData.append('images', images[i]);
    }
    const url = process.env.REACT_APP_API_URL + '/order/import-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  createOrUpdateExportInfoByFile(file: any, images: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    for (let i = 0; i < images?.length; i++) {
      formData.append('images', images[i]);
    }
    const url = process.env.REACT_APP_API_URL + '/order/export-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  getInventories(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/get-inventories';
    return axios.post(url, request);
  },


};
