import { RoleConstant } from "../core/constants/role.constant";

export type Menu = {
    menuId: any;
    menuName: string;
    url: string;
    icon: string;
    orderNo: number;
    roles: any[];
}

export const MENUS: Menu[] = [
    {
        menuId: 1,
        menuName: "Thống kê",
        url: '/dashboard',
        icon: 'ri-home-smile-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 1
    },
    {
        menuId: 2,
        menuName: "Quản lý đơn hàng",
        url: '/order-mgt',
        icon: 'ri-list-unordered',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 2
    },
    {
        menuId: 3,
        menuName: "Quản lý hàng tồn kho",
        url: '/inv-mgt',
        icon: 'ri-grid-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 3
    },
    {
        menuId: 3,
        menuName: "Lưu trữ kiểm hàng",
        url: '/goods-mgt',
        icon: 'ri-folder-image-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 3
    },
    {
        menuId: 4,
        menuName: "Quản lý người dùng",
        url: '/user-mgt',
        icon: 'ri-user-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 4
    },
]