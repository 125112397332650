export class CommonConstant {
  // public static readonly ORDER_STATUS_NEW = "02-01";
  public static readonly ORDER_STATUS_IMPORT = '02-01';
  public static readonly ORDER_STATUS_EXPORT = '02-02';
  public static readonly ORDER_STATUS_CC = '02-03';
  public static readonly ORDER_STATUS_SUCC = '02-04';
  public static readonly ORDER_STATUS_CANCELED = '02-05';

  // public static readonly TRACKING_STATUS_NEW = "03-01";
  public static readonly TRACKING_STATUS_IMPORT = '03-01';
  public static readonly TRACKING_STATUS_EXPORT = '03-02';
  public static readonly TRACKING_STATUS_CC = '03-03';
  public static readonly TRACKING_STATUS_SUCC = '03-04';

  public static readonly SHIPMENT_STATUS_EXPORT = '05-01';
  public static readonly SHIPMENT_STATUS_CC = '05-02';
  public static readonly SHIPMENT_STATUS_SUCC = '05-03';

  public static readonly COLLECTION_ORDER_STATUS = [
    { label: 'Đã nhập kho', value: '02-01' },
    // { label: 'Đã xuất kho', value: '02-02' },
    // { label: 'Đã thông quan', value: '02-03' },
    { label: 'Đang giao hàng', value: '02-06' },
    { label: 'Đã giao thành công', value: '02-04' },
    { label: 'Đã hủy', value: '02-05' }
  ];


  public static readonly ACCOUNT_STATUS_ACTIVATED= "01-02";
  public static readonly ACCOUNT_STATUS_NOT_ACTIVATED= "01-01";
  public static readonly ACCOUNT_STATUS_DISABLED= "01-03";
}
