export default function Dashboard() {
  return (
    <>
      <div className="row g-6">
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-primary h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-primary">
                    <i className="ri-car-line ri-24px"></i>
                  </span>
                </div>
                <h4 className="mb-0">42</h4>
              </div>
              <h6 className="mb-0 fw-normal">On route vehicles</h6>
              <p className="mb-0">
                <span className="me-1 fw-medium">+18.2%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-warning h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-warning">
                    <i className="ri-alert-line ri-24px"></i>
                  </span>
                </div>
                <h4 className="mb-0">8</h4>
              </div>
              <h6 className="mb-0 fw-normal">Vehicles with errors</h6>
              <p className="mb-0">
                <span className="me-1 fw-medium">-8.7%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-danger h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-danger">
                    <i className="ri-route-line ri-24px"></i>
                  </span>
                </div>
                <h4 className="mb-0">27</h4>
              </div>
              <h6 className="mb-0 fw-normal">Deviated from route</h6>
              <p className="mb-0">
                <span className="me-1 fw-medium">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-info h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-info">
                    <i className="ri-time-line ri-24px"></i>
                  </span>
                </div>
                <h4 className="mb-0">13</h4>
              </div>
              <h6 className="mb-0 fw-normal">Late vehicles</h6>
              <p className="mb-0">
                <span className="me-1 fw-medium">-2.5%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>

        <div className="col-xxl-6 order-5 order-xxl-0">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Vehicles overview</h5>
              </div>
              <div className="dropdown">
                <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1" type="button" id="deliveryExceptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-more-2-line ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="deliveryExceptions">
                  <a className="dropdown-item">Select All</a>
                  <a className="dropdown-item">Refresh</a>
                  <a className="dropdown-item">Share</a>
                </div>
              </div>
            </div>
            <div className="card-body pb-2">
              <div className="d-none d-lg-flex vehicles-progress-labels mb-5">
                <div className="vehicles-progress-label on-the-way-text" style={{ width: '39.7%' }}>
                  On the way
                </div>
                <div className="vehicles-progress-label unloading-text" style={{ width: '28.3%' }}>
                  Unloading
                </div>
                <div className="vehicles-progress-label loading-text" style={{ width: '17.4%' }}>
                  Loading
                </div>
                <div className="vehicles-progress-label waiting-text" style={{ width: '14.6%' }}>
                  Waiting
                </div>
              </div>
              {/* <div className="vehicles-overview-progress progress rounded-4 bg-transparent mb-2" style="height: 46px;">
        <div className="progress-bar small fw-medium text-start rounded-start bg-lighter text-heading px-1 px-lg-4" role="progressbar" style="width: 39.7%" aria-valuenow="39.7" aria-valuemin="0" aria-valuemax="100">39.7%</div>
        <div className="progress-bar small fw-medium text-start bg-primary px-1 px-lg-4" role="progressbar" style="width: 28.3%" aria-valuenow="28.3" aria-valuemin="0" aria-valuemax="100">28.3%</div>
        <div className="progress-bar small fw-medium text-start text-bg-info px-1 px-lg-4" role="progressbar" style="width: 17.4%" aria-valuenow="17.4" aria-valuemin="0" aria-valuemax="100">17.4%</div>
        <div className="progress-bar small fw-medium text-start rounded-end bg-gray-900 px-1 px-lg-4" role="progressbar" style="width: 14.6%" aria-valuenow="14.6" aria-valuemin="0" aria-valuemax="100">14.6%</div>
      </div> */}
              <div className="table-responsive">
                <table className="table card-table">
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-car-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">On the way</h6>
                        </div>
                      </td>
                      <td className="text-end pe-0 text-nowrap">
                        <h6 className="mb-0">2hr 10min</h6>
                      </td>
                      <td className="text-end pe-0 ps-6">
                        <span>39.7%</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-download-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Unloading</h6>
                        </div>
                      </td>
                      <td className="text-end pe-0 text-nowrap">
                        <h6 className="mb-0">3hr 15min</h6>
                      </td>
                      <td className="text-end pe-0 ps-6">
                        <span>28.3%</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-upload-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Loading</h6>
                        </div>
                      </td>
                      <td className="text-end pe-0 text-nowrap">
                        <h6 className="mb-0">1hr 24min</h6>
                      </td>
                      <td className="text-end pe-0 ps-6">
                        <span>17.4%</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-time-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Waiting</h6>
                        </div>
                      </td>
                      <td className="text-end pe-0 text-nowrap">
                        <h6 className="mb-0">5hr 19min</h6>
                      </td>
                      <td className="text-end pe-0 ps-6">
                        <span>14.6%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xxl-6 order-3 order-xxl-1">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2 mb-1">Shipment statistics</h5>
                <p className="card-subtitle mb-0">Total number of deliveries 23.8k</p>
              </div>
              <div className="btn-group">
                <button type="button" className="btn btn-outline-primary" data-bs-toggle="dropdown">
                  January
                </button>
                <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">January</a>
                  </li>
                  <li>
                    <a className="dropdown-item">February</a>
                  </li>
                  <li>
                    <a className="dropdown-item">March</a>
                  </li>
                  <li>
                    <a className="dropdown-item">April</a>
                  </li>
                  <li>
                    <a className="dropdown-item">May</a>
                  </li>
                  <li>
                    <a className="dropdown-item">June</a>
                  </li>
                  <li>
                    <a className="dropdown-item">July</a>
                  </li>
                  <li>
                    <a className="dropdown-item">August</a>
                  </li>
                  <li>
                    <a className="dropdown-item">September</a>
                  </li>
                  <li>
                    <a className="dropdown-item">October</a>
                  </li>
                  <li>
                    <a className="dropdown-item">November</a>
                  </li>
                  <li>
                    <a className="dropdown-item">December</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <div id="shipmentStatisticsChart"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xxl-4 order-2 order-xxl-2">
          <div className="card h-100">
            <div className="card-header d-flex justify-content-between">
              <div>
                <h5 className="card-title mb-1">Delivery Performance</h5>
                <p className="card-subtitle mb-0">12% increase in this month</p>
              </div>
              <div className="dropdown">
                <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1" type="button" id="deliveryPerformance" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-more-2-line ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="deliveryPerformance">
                  <a className="dropdown-item">Select All</a>
                  <a className="dropdown-item">Refresh</a>
                  <a className="dropdown-item">Share</a>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="p-0 m-0">
                <li className="d-flex mb-6 pb-1">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-primary">
                      <i className="ri-gift-line ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Packages in transit</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ri-arrow-up-s-line ri-24px"></i>
                        25.8%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">10k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6 pb-1">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-info">
                      <i className="ri-car-line ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Packages out for delivery</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ri-arrow-up-s-line ri-24px"></i>
                        4.3%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">5k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6 pb-1">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-success">
                      <i className="ri-check-line text-success ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Packages delivered</h6>
                      <small className="text-danger fw-normal d-block">
                        <i className="ri-arrow-down-s-line ri-24px"></i>
                        12.5
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">15k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6 pb-1">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-warning">
                      <i className="ri-home-line ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Delivery success rate</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ri-arrow-up-s-line ri-24px"></i>
                        35.6%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">95%</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6 pb-1">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-secondary">
                      <i className="ri-timer-line ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Average delivery time</h6>
                      <small className="text-danger fw-normal d-block">
                        <i className="ri-arrow-down-s-line ri-24px"></i>
                        2.15
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">2.5 Days</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-3 bg-label-danger">
                      <i className="ri-user-line ri-24px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Customer satisfaction</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ri-arrow-up-s-line ri-24px"></i>
                        5.7%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="mb-0">4.5/5</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-4 order-1 order-xxl-3">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Reasons for delivery exceptions</h5>
              </div>
              <div className="dropdown">
                <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1" type="button" id="deliveryExceptionsReasons" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-more-2-line ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="deliveryExceptionsReasons">
                  <a className="dropdown-item">Select All</a>
                  <a className="dropdown-item">Refresh</a>
                  <a className="dropdown-item">Share</a>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div id="deliveryExceptionsChart"></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-4 order-0 order-xxl-4">
          <div className="card h-100">
            <div className="card-header d-flex justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Orders by Countries</h5>
                <span className="text-body mb-0">62 deliveries in progress</span>
              </div>
              <div className="dropdown">
                <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1" type="button" id="ordersCountries" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-more-2-line ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="ordersCountries">
                  <a className="dropdown-item">Select All</a>
                  <a className="dropdown-item">Refresh</a>
                  <a className="dropdown-item">Share</a>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="nav-align-top">
                <ul className="nav nav-tabs nav-fill" role="tablist">
                  <li className="nav-item">
                    <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-new" aria-controls="navs-justified-new" aria-selected="true">
                      New
                    </button>
                  </li>
                  <li className="nav-item">
                    <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-preparing" aria-controls="navs-justified-link-preparing" aria-selected="false">
                      Preparing
                    </button>
                  </li>
                  <li className="nav-item">
                    <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-shipping" aria-controls="navs-justified-link-shipping" aria-selected="false">
                      Shipping
                    </button>
                  </li>
                </ul>
                <div className="tab-content border-0 pb-0 px-6 mx-1">
                  <div className="tab-pane fade show active" id="navs-justified-new" role="tabpanel">
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Myrtle Ullrich</h6>
                          <p className="mb-0 small">101 Boulder, California(CA), 95959</p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Barry Schowalter</h6>
                          <p className="mb-0 small">939 Orange, California(CA), 92118</p>
                        </div>
                      </li>
                    </ul>
                    <div className="border-1 border-light border-top border-dashed mb-2"></div>
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Veronica Herman</h6>
                          <p className="mb-0 small">162 Windsor, California(CA), 95492</p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Helen Jacobs</h6>
                          <p className="mb-0 small">487 Sunset, California(CA), 94043</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane fade" id="navs-justified-link-preparing" role="tabpanel">
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Barry Schowalter</h6>
                          <p className="mb-0 small">939 Orange, California(CA), 92118</p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent border-left-dashed">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Myrtle Ullrich</h6>
                          <p className="mb-0 small">101 Boulder, California(CA), 95959 </p>
                        </div>
                      </li>
                    </ul>
                    <div className="border-1 border-light border-top border-dashed mb-2 "></div>
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Veronica Herman</h6>
                          <p className="mb-0 small">162 Windsor, California(CA), 95492</p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Helen Jacobs</h6>
                          <p className="mb-0 small">487 Sunset, California(CA), 94043</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane fade" id="navs-justified-link-shipping" role="tabpanel">
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Veronica Herman</h6>
                          <p className="mb-0 small">101 Boulder, California(CA), 95959</p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Barry Schowalter</h6>
                          <p className="mb-0 small">939 Orange, California(CA), 92118</p>
                        </div>
                      </li>
                    </ul>
                    <div className="border-1 border-light border-top border-dashed mb-2 "></div>
                    <ul className="timeline mb-0">
                      <li className="timeline-item ps-6 border-left-dashed">
                        <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                          <i className="ri-checkbox-circle-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-success text-uppercase">sender</small>
                          </div>
                          <h6 className="my-50">Myrtle Ullrich</h6>
                          <p className="mb-0 small">162 Windsor, California(CA), 95492 </p>
                        </div>
                      </li>
                      <li className="timeline-item ps-6 border-transparent">
                        <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                          <i className="ri-map-pin-line ri-20px"></i>
                        </span>
                        <div className="timeline-event ps-1">
                          <div className="timeline-header">
                            <small className="text-primary text-uppercase">Receiver</small>
                          </div>
                          <h6 className="my-50">Helen Jacobs</h6>
                          <p className="mb-0 small">487 Sunset, California(CA), 94043</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 order-5">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">On route vehicles</h5>
              </div>
              <div className="dropdown">
                <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1" type="button" id="routeVehicles" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ri-more-2-line ri-20px"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="routeVehicles">
                  <a className="dropdown-item">Select All</a>
                  <a className="dropdown-item">Refresh</a>
                  <a className="dropdown-item">Share</a>
                </div>
              </div>
            </div>
            <div className="card-datatable table-responsive">
              <table className="dt-route-vehicles table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>location</th>
                    <th>starting route</th>
                    <th>ending route</th>
                    <th>warnings</th>
                    <th className="w-20">progress</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
