import { forwardRef, useId } from 'react';

type FormFloatingProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: any;
  required?: boolean | undefined;
};

const FormFloating = forwardRef<HTMLInputElement, FormFloatingProps>(({required, errMsg, isError, label, className, ...restProps }, ref) => {
  const inputId = useId();

  return (
    <div className={'form-floating form-floating-outline ' + (className ? className : '')}>
      <input {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} />
      <label htmlFor={inputId}>{label}{required && <span className='error-feedback'> *</span>}</label>
      {isError && <div className="error-feedback"> {errMsg} </div>}
    </div>
  );
});
export default FormFloating;
