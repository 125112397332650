import { RoleConstant } from '../../core/constants/role.constant';

export default function ViewRole({ roleId }: any) {
  if (roleId == RoleConstant.ROLE_ADMIN) return <span className="badge rounded-pill bg-label-primary">Người quản trị</span>;
  if (roleId == RoleConstant.ROLE_CUSTOMER) return <span className="badge rounded-pill bg-label-secondary">Khách hàng</span>;
  if (roleId == RoleConstant.ROLE_SELLER) return <span className="badge rounded-pill bg-label-warning">Nhân viên bán hàng</span>;
  if (roleId == RoleConstant.ROLE_WHTQ) return <span className="badge rounded-pill bg-label-info">Quản lý kho TQ</span>;
  if (roleId == RoleConstant.ROLE_WHVN) return <span className="badge rounded-pill bg-label-success">Quản lý kho VN</span>;
  return <></>
}
